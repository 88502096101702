/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonApiLog
 */
export interface JsonApiLog {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonApiLog
     */
    allowedActions?: Array<JsonApiLogAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonApiLog
     */
    ammoLogId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonApiLog
     */
    ammoStatus?: JsonApiLogAmmoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonApiLog
     */
    apiResult?: JsonApiLogApiResultEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonApiLog
     */
    apiType?: JsonApiLogApiTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonApiLog
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiLog
     */
    cateringMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonApiLog
     */
    cateringStatus?: JsonApiLogCateringStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonApiLog
     */
    cateringType?: JsonApiLogCateringTypeEnum;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonApiLog
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonApiLog
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonApiLog
     */
    dayNo?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonApiLog
     */
    errorMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonApiLog
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonApiLog
     */
    logId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonApiLog
     */
    placeId?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonApiLogAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonApiLogAmmoStatusEnum {
    Used = 'USED',
    Ignored = 'IGNORED'
}/**
* @export
* @enum {string}
*/
export enum JsonApiLogApiResultEnum {
    Ok = 'OK',
    Error = 'ERROR'
}/**
* @export
* @enum {string}
*/
export enum JsonApiLogApiTypeEnum {
    Check = 'check',
    Catering = 'catering',
    Ammo = 'ammo'
}/**
* @export
* @enum {string}
*/
export enum JsonApiLogCateringStatusEnum {
    OkAvailable = 'OK_AVAILABLE',
    OkUsingNow = 'OK_USING_NOW',
    ErrorAlreadyUsed = 'ERROR_ALREADY_USED',
    ErrorGroupUsed = 'ERROR_GROUP_USED',
    ErrorNoCatering = 'ERROR_NO_CATERING',
    ErrorWrongPlace = 'ERROR_WRONG_PLACE'
}/**
* @export
* @enum {string}
*/
export enum JsonApiLogCateringTypeEnum {
    Br = 'BR',
    Lu = 'LU',
    Di = 'DI',
    Sn = 'SN'
}

export function JsonApiLogFromJSON(json: any): JsonApiLog {
    return JsonApiLogFromJSONTyped(json, false);
}

export function JsonApiLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonApiLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'ammoLogId': !exists(json, 'ammoLogId') ? undefined : json['ammoLogId'],
        'ammoStatus': !exists(json, 'ammoStatus') ? undefined : json['ammoStatus'],
        'apiResult': !exists(json, 'apiResult') ? undefined : json['apiResult'],
        'apiType': !exists(json, 'apiType') ? undefined : json['apiType'],
        'barcode': !exists(json, 'barcode') ? undefined : json['barcode'],
        'cateringMessage': !exists(json, 'cateringMessage') ? undefined : json['cateringMessage'],
        'cateringStatus': !exists(json, 'cateringStatus') ? undefined : json['cateringStatus'],
        'cateringType': !exists(json, 'cateringType') ? undefined : json['cateringType'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'dayNo': !exists(json, 'dayNo') ? undefined : json['dayNo'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'logId': !exists(json, 'logId') ? undefined : json['logId'],
        'placeId': !exists(json, 'placeId') ? undefined : json['placeId'],
    };
}

export function JsonApiLogToJSON(value?: JsonApiLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'ammoLogId': value.ammoLogId,
        'ammoStatus': value.ammoStatus,
        'apiResult': value.apiResult,
        'apiType': value.apiType,
        'barcode': value.barcode,
        'cateringMessage': value.cateringMessage,
        'cateringStatus': value.cateringStatus,
        'cateringType': value.cateringType,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'dayNo': value.dayNo,
        'errorMessage': value.errorMessage,
        'eventId': value.eventId,
        'logId': value.logId,
        'placeId': value.placeId,
    };
}

