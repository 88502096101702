import {Alert, Button, Grid, Tooltip, Typography} from '@mui/material';
import {FormikErrors, FormikProps} from 'formik';
import {createOption, formatMoney, FormProps, OptionValue} from "../../model/form";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {PartyMassActionProps, PartyMassActionValues, partyName} from "../../model/party";
import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridMode, DataGridState, defaultDataGridFilterState} from "../DataGrid";
import {
    JsonEventParty,
    JsonEventPartyCompInfoStatusEnum,
    JsonEventPartyInfo,
    JsonEventPartyMassActionRequest,
    JsonEventPartyMassActionRequestActionEnum,
    JsonEventPartyTravelInfoStatusEnum
} from "../../generated-api";
import CodebookValue from "../CodebookValue";
import {dateToGuiAs} from "../../helpers/date";
import {ItemsState, useAppSelector} from "../../store";
import {ButtonGroupPlain} from "../form/ButtonGroupField";
import {EventPartyComp, EventPartyMissingInfo} from "../../pages/EventPartiesPage";
import {AlertColor} from "@mui/material/Alert/Alert";
import {CheckboxField} from "../form/CheckboxField";
import {SendRounded} from "@mui/icons-material";
import {ModalProps, useModal} from "../../services/modal";
import {AuthState} from "../../store/auth";
import {selectAuthInfo} from "../../store/selectors";

interface Props extends PartyMassActionProps<PartyMassActionValues<any>, any> {

}

interface LocalFilter extends DataGridFilter {
    action?: JsonEventPartyMassActionRequestActionEnum;
    eventId?: number;
}

interface EventPartiesGridState extends DataGridState<JsonEventPartyInfo, LocalFilter> {
}

type ShowMode = 'valid' | 'invalid' | 'all';

const showModeOptions: OptionValue[] = [
    createOption('valid', 'Ukázat jen ty v pořádku', 'Zobrazit jen osoby v pořádku', undefined, 'warning'),
    createOption('invalid', 'Chyby', 'Zobrazit jen osoby s chybou', undefined, 'warning'),
    createOption('all', 'Vše', 'Zobrazit vše', undefined, 'warning'),
];

const addPartyInfo = (key: any, title: JSX.Element | string | undefined, desc: string, severity: AlertColor) => {
    return <Tooltip key={key} title={desc}><Typography color={severity}>{title}</Typography></Tooltip>
}

type EventPartyDetailsProps = {
    item: JsonEventPartyInfo,
    filter: LocalFilter,
}

const EventPartyDetails = (props: EventPartyDetailsProps) => {
    const {item} = props;

    const t = useAppTranslation();

    const rows: JSX.Element[] = [];

    const rowStatusType: RowStatusType = getRowStatus(item);
    switch (rowStatusType) {
        case 'alreadyPaid':
            rows.push(addPartyInfo('alreadyPaid',
                <>{t('Již vyplaceno')}</>,
                t('Všechny náhrady/výplaty byly již vyplaceny'), 'error'));
            break;
        case 'noComp':
            rows.push(addPartyInfo('noComp',
                <>{t('Žádná výplata')}</>,
                t('Osoba nemá zadané náhrady any odměny'), 'error'));
            break;
        case 'missingBankAccount':
            rows.push(addPartyInfo('noComp',
                <>{t('Chybí účet')}</>,
                t('Není kam poslat výplatu'), 'error'));
            break;
        case 'valid':
        default:
            break;
    }

    if (!rows.length) {
        return null;
    }

    return <div className={'event-details'}>
        {rows}
    </div>
}

const defaultState: EventPartiesGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: undefined,
        action: undefined,
        eventId: undefined
    },
};

type RowStatusType = 'valid' | 'missingBankAccount' | 'noComp' | 'alreadyPaid';

const getRowStatus = (item: JsonEventPartyInfo): RowStatusType => {
    if (!item.bankAccount) {
        return 'missingBankAccount';
    }
    if (!(item.eventCompDetails?.length && item.eventCompDetails?.length > 0)
        && !(item.eventTravelDetails?.length && item.eventTravelDetails?.length > 0)) {
        return 'noComp';
    }
    if (!(item.eventCompDetails?.length && item.eventCompDetails.find((epc) => epc.status === JsonEventPartyCompInfoStatusEnum.Active))
        && !(item.eventTravelDetails?.length && item.eventTravelDetails.find((ept) => ept.status === JsonEventPartyTravelInfoStatusEnum.Active))) {
        return 'alreadyPaid';
    }

    return 'valid';
}

const getRowClassNames = (item: JsonEventPartyInfo): string[] | undefined => {
    switch (getRowStatus(item)) {
        case 'noComp':
        case 'missingBankAccount':
        case 'alreadyPaid':
            return ['data-grid-invalid-row'];
    }
    return undefined;
}

const filterValid = (action: JsonEventPartyMassActionRequestActionEnum | undefined, eventParties: JsonEventPartyInfo[], showMode: ShowMode) => {
    let alreadyPaid = 0, noComp = 0, missingBankAccount = 0;
    const filtered = eventParties.filter((item) => {
        let valid = false;
        switch (getRowStatus(item)) {
            case 'alreadyPaid':
                alreadyPaid++;
                break;
            case 'noComp':
                noComp++;
                break;
            case 'missingBankAccount':
                missingBankAccount++;
                break;
            case 'valid':
            default:
                valid = true;
        }

        return showMode === 'all' || (valid && showMode === 'valid') || (!valid && showMode === 'invalid');
    });
    return {filtered, alreadyPaid, noComp, missingBankAccount};
}

const texts: { [key in JsonEventPartyMassActionRequestActionEnum]?: [string, string, string] } = {
    [JsonEventPartyMassActionRequestActionEnum.Payment]: [
        'Vygenerovat příkazy',
        'Soubor bude obsahovat platby pro {{count}} osob',
        'Není nikdo, pro koho by bylo možné vytvořit platbu'],
};

const PartyPaymentForm = (props: Props) => {

    const {massAction, onSave} = props;
    const {eventParties} = massAction;
    const {action, eventId} = massAction.values;

    const t = useAppTranslation();
    const modal = useModal();

    const {configuration} = useAppSelector<AuthState>(selectAuthInfo);

    const [showMode, setShowMode] = useState<ShowMode>('valid');

    const validate = useCallback((values: JsonEventPartyMassActionRequest) => {
        let errors = {} as FormikErrors<JsonEventPartyMassActionRequest>;
        if (!values.eventId) {
            errors.eventId = t('Vyberte událost');
        }
        return errors;
    }, [t]);

    const actions = useCallback(({values, isSubmitting}: FormikProps<JsonEventPartyMassActionRequest>, props: FormProps<JsonEventPartyMassActionRequest>) => {
        const {items} = values;
        const itemsCount = items?.length || 0;
        if (!action) {
            return null;
        }
        const textValues = texts[action];
        if (!textValues) {
            return null; // should never happen
        }

        return <Grid item xs={12} sx={{margin: '-5px 0 -10px 0'}}>
            <Grid container columnSpacing={2} justifyContent="flex-end">
                <Grid item sx={{flexGrow: 1}}>
                    {itemsCount > 0
                        ? <Alert severity={'info'} className={'event-action-errors'}>{t(textValues[1], {count: itemsCount})}</Alert>
                        : <Alert severity={'warning'} className={'event-action-errors'}>{t(textValues[2])}</Alert>}
                </Grid>
                <Grid item>
                    <CheckboxField name={'confirmPayment'} color={'default'} label={t('Potvrdit jako vyplacené')}/>
                </Grid>
                {props.onCancel && <Grid item>
					<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || t('Storno')}</Button>
				</Grid>}
                <Grid item>
                    <Button variant="contained" type="submit" color={'success'} disabled={isSubmitting || !itemsCount}>
                        {t(textValues[0])}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    }, [action, t]);

    const {filtered, alreadyPaid, noComp, missingBankAccount} = useMemo(() => {
        return filterValid(action, eventParties, showMode);
    }, [action, eventParties, showMode])

    const cols = useMemo(() => {
        const cols: DataGridCol<JsonEventPartyInfo, LocalFilter>[] = [
            createCol('Jméno', 'fullName', 150, undefined, (v, item) => <strong>{partyName(item)}</strong>),
            createCol('P', 'sex', 15, 'Pohlaví', (v) => v ? <small><CodebookValue value={v} name={'sex'} formatValue={(v) => v[0]}/></small> : null),
            createCol('R', 'birthDate', 35, 'Rok narození', (v) => v ? <small>{dateToGuiAs(v, 'Y')}</small> : null),
            {
                title: 'Ú',
                tooltip: 'Chybějící údaje',
                size: 20,
                align: "center",
                col: 'email',
                renderValue: (v, item) => {
                    return <EventPartyMissingInfo item={item}/>
                }
            },
            createCol('Email', 'email', 150, undefined, (v) => <small>{v}</small>),
            createCol('Skupina', 'groupId', 100, 'Pracovní skupina', (v, item) => v ? <CodebookValue value={v} name={'group'} item={item}/> : null),
            {
                col: 'eventCompDetails',
                title: '$',
                tooltip: 'Odměny',
                size: 22,
                align: 'center',
                renderValue: (v, item) => <EventPartyComp item={item} eventPartyComps={v}/>
            },
            {
                title: 'Stav',
                size: 100,
                col: 'updateInviteStatus',
                renderValue: (v, item, filter) => <EventPartyDetails item={item} filter={filter as LocalFilter}/>
            }
        ];
        return cols;
    }, []);

    const getRowClassNamesWithInvite = useCallback((item: JsonEventPartyInfo) => {
        return getRowClassNames(item);
    }, []);

    const grid = useMemo(() => {
        const itemsState = {
            loading: false,
            count: 0,
            items: filtered,
            filter: {action, eventId}
        } as ItemsState<JsonEventPartyInfo, LocalFilter>;

        return <>
            {(alreadyPaid > 0 || noComp > 0 || missingBankAccount > 0) && <Grid container style={{padding: '5px 0 10px 0', alignItems: 'center'}}><Grid item xs={8}>
				<Alert severity={'warning'} className={'event-action-errors'}>
                    {t('Některé osoby nelze vyzvat ({{skipped}}):', {skipped: alreadyPaid + noComp + missingBankAccount})}
                    {alreadyPaid > 0 && <span>{t('již vyplaceno ({{alreadyPaid}})', {alreadyPaid})}</span>}
                    {noComp > 0 && <span>{t('žádná výplata ({{noComp}})', {noComp})}</span>}
                    {missingBankAccount > 0 && <span>{t('chybí účet ({{missingBankAccount}})', {missingBankAccount})}</span>}
				</Alert>
			</Grid><Grid item xs={4} sx={{textAlign: 'right'}}>
				<ButtonGroupPlain name={'showMode'} options={showModeOptions} currentValue={showMode} onChange={(v) => {
                    setShowMode(v || showMode);
                }}/>
			</Grid></Grid>}
            <DataGrid
                cols={cols}
                defaultState={defaultState}
                itemsState={itemsState}
                mode={DataGridMode.CLIENT}
                tableProps={{
                    minHeight: '100px',
                    maxHeight: "clamp(100px, calc(100vh - "
                        + (300
                            + (alreadyPaid > 0 || noComp > 0 || missingBankAccount > 0 ? 50 : 0))
                        + "px), 400px)"
                }}
                emptyListMessage={t('Není možné vyzvat žádné osoby')}
                getRowClassNames={getRowClassNamesWithInvite}
            />
        </>;
    }, [filtered, alreadyPaid, noComp, missingBankAccount, action, cols, eventId, showMode, t, getRowClassNamesWithInvite]);

    const children = useCallback(() => {
        return <>
            <Grid item xs={12}>
                {grid}
            </Grid>
        </>;
    }, [grid]);

    const handleSave = useCallback(async (values: JsonEventPartyMassActionRequest) => {
        let total = 0;
        values?.items?.forEach((v) => {
            const ep = v as JsonEventPartyInfo;
            ep.eventCompDetails?.forEach((epc) => {
                if (epc.status === JsonEventPartyCompInfoStatusEnum.Active) {
                    total += epc.compAmount || 0;
                }
            });
            ep.eventTravelDetails?.forEach((ept) => {
                if (ept.status === JsonEventPartyTravelInfoStatusEnum.Active) {
                    total += ept.compAmount || 0;
                }
            });
        })

        const result = await modal.confirm({
            title: t('Potvrzení vygenerování souboru plateb'),
            message: <div>
                <p>{t('Pro zvolené osoby ({{count}}) bude vytvořen soubor s platbami (formát ABO).', {count: values.items?.length})}</p>
                <p>{t('Celkem k výplatě: {{amount}}', {amount: formatMoney(total, true)})}</p>
                <br/>
                {!!values.confirmPayment
                    ? <Alert severity={'error'}>{t('Uvedené platby budou v EMS označeny jako "vyplacené", soubor již nepůjde vygenerovat znovu')}</Alert>
                    : <Alert severity={'warning'}>{t('Uvedené platby prozatím nebudou v EMS označeny jako "vyplacené".')}</Alert>}
            </div>,
            cancelText: 'Zpět',
            confirmColor: 'success',
            confirmText: t('Vygenerovat soubor ({{count}})', {count: values.items?.length}),
            confirmIcon: <SendRounded/>,
        } as ModalProps);
        if (result !== 'CONFIRM') {
            return;
        }

        if (onSave && massAction) {
            await onSave({...massAction, values})
        }
    }, [massAction, onSave, modal, t]);

    const initialValues = useMemo(() => {
        return {
            ...massAction.values,
            items: filtered as JsonEventParty[] | undefined,
            invite: {
                replyUntil: configuration?.defaultEvent?.eventData?.replyUntilOrgComp,
                inviteData: {}
            }
        };
    }, [massAction, filtered, configuration?.defaultEvent?.eventData?.replyUntilOrgComp]);

    return <FormContainer
        item={initialValues}
        validate={validate}
        actions={actions}
        children={children}
        onCancel={props.onCancel}
        onSave={handleSave}
    />;
}

export default PartyPaymentForm;
