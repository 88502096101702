import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonAmmoLog, ApiResultOfJsonAmmoLogInfo, AmmoLogControllerApi, GetAmmoLogCountUsingGETRequest, GetAmmoLogListUsingGETRequest, JsonAmmoLog, JsonAmmoLogInfo} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const ammoLogsApi = new AmmoLogControllerApi(API_CONFIG);

export const fetchAmmoLogs = createAsyncThunk('ammoLogs/list', async (filter: GetAmmoLogListUsingGETRequest, thunkApi) => {
    return {...await ammoLogsApi.getAmmoLogListUsingGET(filter), filter};
});

export const fetchAmmoLogsCount = createAsyncThunk('ammoLogs/count', async (params: GetAmmoLogCountUsingGETRequest = {}) => {
    return await ammoLogsApi.getAmmoLogCountUsingGET(params);
});

export const fetchAmmoLogInfo = createAsyncThunk('ammoLog/info', async (ammoLogId: number): Promise<ApiResultOfJsonAmmoLogInfo> => {
    return await ammoLogsApi.getAmmoLogInfoUsingGET({ammoLogId});
});

export const fetchAmmoLog = createAsyncThunk('ammoLog/fetch', async (ammoLogId: number): Promise<ApiResultOfJsonAmmoLog> => {
    return await ammoLogsApi.getAmmoLogUsingGET({ammoLogId});
});

export const saveAmmoLog = createAsyncThunk('ammoLog/save', async (ammoLog: JsonAmmoLog) => {
    return await ammoLogsApi.saveAmmoLogUsingPOST({json: ammoLog});
});

export const ammoLogsSlice = createSlice({
    name: 'ammoLogs',
    initialState: (): ItemsState<JsonAmmoLogInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchAmmoLogs, fetchAmmoLogsCount);
    }
});

export const ammoLogsReducer = ammoLogsSlice.reducer;
