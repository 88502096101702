/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonArticle,
    JsonArticleFromJSON,
    JsonArticleFromJSONTyped,
    JsonArticleToJSON,
} from './JsonArticle';
import {
    JsonEventParty,
    JsonEventPartyFromJSON,
    JsonEventPartyFromJSONTyped,
    JsonEventPartyToJSON,
} from './JsonEventParty';
import {
    JsonEventPartyComp,
    JsonEventPartyCompFromJSON,
    JsonEventPartyCompFromJSONTyped,
    JsonEventPartyCompToJSON,
} from './JsonEventPartyComp';
import {
    JsonEventPartyDay,
    JsonEventPartyDayFromJSON,
    JsonEventPartyDayFromJSONTyped,
    JsonEventPartyDayToJSON,
} from './JsonEventPartyDay';
import {
    JsonInvite,
    JsonInviteFromJSON,
    JsonInviteFromJSONTyped,
    JsonInviteToJSON,
} from './JsonInvite';

/**
 * 
 * @export
 * @interface JsonEventPartyMassActionRequest
 */
export interface JsonEventPartyMassActionRequest {
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyMassActionRequest
     */
    action?: JsonEventPartyMassActionRequestActionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JsonEventPartyMassActionRequest
     */
    confirmPayment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyMassActionRequest
     */
    contractStatus?: JsonEventPartyMassActionRequestContractStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyMassActionRequest
     */
    eventId?: number;
    /**
     * 
     * @type {Array<JsonEventPartyComp>}
     * @memberof JsonEventPartyMassActionRequest
     */
    eventPartyComps?: Array<JsonEventPartyComp>;
    /**
     * 
     * @type {Array<JsonEventPartyDay>}
     * @memberof JsonEventPartyMassActionRequest
     */
    eventPartyDays?: Array<JsonEventPartyDay>;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyMassActionRequest
     */
    groupId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyMassActionRequest
     */
    groupPosition?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventPartyMassActionRequest
     */
    ids?: Array<string>;
    /**
     * 
     * @type {JsonInvite}
     * @memberof JsonEventPartyMassActionRequest
     */
    invite?: JsonInvite;
    /**
     * 
     * @type {Array<JsonEventParty>}
     * @memberof JsonEventPartyMassActionRequest
     */
    items?: Array<JsonEventParty>;
    /**
     * 
     * @type {boolean}
     * @memberof JsonEventPartyMassActionRequest
     */
    prefAcm?: boolean;
    /**
     * 
     * @type {Array<JsonArticle>}
     * @memberof JsonEventPartyMassActionRequest
     */
    prefArticles?: Array<JsonArticle>;
    /**
     * 
     * @type {Array<JsonArticle>}
     * @memberof JsonEventPartyMassActionRequest
     */
    recArticles?: Array<JsonArticle>;
    /**
     * 
     * @type {boolean}
     * @memberof JsonEventPartyMassActionRequest
     */
    skipEmail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyMassActionRequest
     */
    updateType?: JsonEventPartyMassActionRequestUpdateTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventPartyMassActionRequestActionEnum {
    Register = 'REGISTER',
    InviteGuest = 'INVITE_GUEST',
    InviteOrg = 'INVITE_ORG',
    InviteSupp = 'INVITE_SUPP',
    UpdateOrg = 'UPDATE_ORG',
    TravelOrg = 'TRAVEL_ORG',
    CompOrg = 'COMP_ORG',
    Update = 'UPDATE',
    Delete = 'DELETE',
    Import = 'IMPORT',
    Accred = 'ACCRED',
    Seating = 'SEATING',
    Comp = 'COMP',
    Email = 'EMAIL',
    Payment = 'PAYMENT',
    PrintContract = 'PRINT_CONTRACT'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyMassActionRequestContractStatusEnum {
    None = 'NONE',
    Signed = 'SIGNED'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyMassActionRequestUpdateTypeEnum {
    Article = 'ARTICLE',
    Days = 'DAYS',
    Group = 'GROUP',
    ArticleContract = 'ARTICLE_CONTRACT',
    Comp = 'COMP',
    Parking = 'PARKING'
}

export function JsonEventPartyMassActionRequestFromJSON(json: any): JsonEventPartyMassActionRequest {
    return JsonEventPartyMassActionRequestFromJSONTyped(json, false);
}

export function JsonEventPartyMassActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventPartyMassActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : json['action'],
        'confirmPayment': !exists(json, 'confirmPayment') ? undefined : json['confirmPayment'],
        'contractStatus': !exists(json, 'contractStatus') ? undefined : json['contractStatus'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventPartyComps': !exists(json, 'eventPartyComps') ? undefined : ((json['eventPartyComps'] as Array<any>).map(JsonEventPartyCompFromJSON)),
        'eventPartyDays': !exists(json, 'eventPartyDays') ? undefined : ((json['eventPartyDays'] as Array<any>).map(JsonEventPartyDayFromJSON)),
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'groupPosition': !exists(json, 'groupPosition') ? undefined : json['groupPosition'],
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'invite': !exists(json, 'invite') ? undefined : JsonInviteFromJSON(json['invite']),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(JsonEventPartyFromJSON)),
        'prefAcm': !exists(json, 'prefAcm') ? undefined : json['prefAcm'],
        'prefArticles': !exists(json, 'prefArticles') ? undefined : ((json['prefArticles'] as Array<any>).map(JsonArticleFromJSON)),
        'recArticles': !exists(json, 'recArticles') ? undefined : ((json['recArticles'] as Array<any>).map(JsonArticleFromJSON)),
        'skipEmail': !exists(json, 'skipEmail') ? undefined : json['skipEmail'],
        'updateType': !exists(json, 'updateType') ? undefined : json['updateType'],
    };
}

export function JsonEventPartyMassActionRequestToJSON(value?: JsonEventPartyMassActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'confirmPayment': value.confirmPayment,
        'contractStatus': value.contractStatus,
        'eventId': value.eventId,
        'eventPartyComps': value.eventPartyComps === undefined ? undefined : ((value.eventPartyComps as Array<any>).map(JsonEventPartyCompToJSON)),
        'eventPartyDays': value.eventPartyDays === undefined ? undefined : ((value.eventPartyDays as Array<any>).map(JsonEventPartyDayToJSON)),
        'groupId': value.groupId,
        'groupPosition': value.groupPosition,
        'ids': value.ids,
        'invite': JsonInviteToJSON(value.invite),
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(JsonEventPartyToJSON)),
        'prefAcm': value.prefAcm,
        'prefArticles': value.prefArticles === undefined ? undefined : ((value.prefArticles as Array<any>).map(JsonArticleToJSON)),
        'recArticles': value.recArticles === undefined ? undefined : ((value.recArticles as Array<any>).map(JsonArticleToJSON)),
        'skipEmail': value.skipEmail,
        'updateType': value.updateType,
    };
}

