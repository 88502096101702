/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonAmmoLog,
    ApiResultOfJsonAmmoLogFromJSON,
    ApiResultOfJsonAmmoLogToJSON,
    ApiResultOfJsonAmmoLogInfo,
    ApiResultOfJsonAmmoLogInfoFromJSON,
    ApiResultOfJsonAmmoLogInfoToJSON,
    ApiResultOfListOfJsonAmmoLogInfo,
    ApiResultOfListOfJsonAmmoLogInfoFromJSON,
    ApiResultOfListOfJsonAmmoLogInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonAmmoLog,
    JsonAmmoLogFromJSON,
    JsonAmmoLogToJSON,
} from '../models';

export interface GetAmmoLogCountUsingGETRequest {
    eventId?: number;
    partyIds?: Array<number>;
    search?: string;
}

export interface GetAmmoLogInfoUsingGETRequest {
    ammoLogId: number;
}

export interface GetAmmoLogListUsingGETRequest {
    eventId?: number;
    partyIds?: Array<number>;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetAmmoLogUsingGETRequest {
    ammoLogId: number;
}

export interface SaveAmmoLogUsingPOSTRequest {
    json: JsonAmmoLog;
    testOnly?: boolean;
}

/**
 * 
 */
export class AmmoLogControllerApi extends runtime.BaseAPI {

    /**
     * getAmmoLogCount
     */
    async getAmmoLogCountUsingGETRaw(requestParameters: GetAmmoLogCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.partyIds) {
            queryParameters['partyIds'] = requestParameters.partyIds;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/ammo/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getAmmoLogCount
     */
    async getAmmoLogCountUsingGET(requestParameters: GetAmmoLogCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getAmmoLogCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAmmoLogInfo
     */
    async getAmmoLogInfoUsingGETRaw(requestParameters: GetAmmoLogInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonAmmoLogInfo>> {
        if (requestParameters.ammoLogId === null || requestParameters.ammoLogId === undefined) {
            throw new runtime.RequiredError('ammoLogId','Required parameter requestParameters.ammoLogId was null or undefined when calling getAmmoLogInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/ammo/info/{ammoLogId}`.replace(`{${"ammoLogId"}}`, encodeURIComponent(String(requestParameters.ammoLogId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonAmmoLogInfoFromJSON(jsonValue));
    }

    /**
     * getAmmoLogInfo
     */
    async getAmmoLogInfoUsingGET(requestParameters: GetAmmoLogInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonAmmoLogInfo> {
        const response = await this.getAmmoLogInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAmmoLogList
     */
    async getAmmoLogListUsingGETRaw(requestParameters: GetAmmoLogListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonAmmoLogInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.partyIds) {
            queryParameters['partyIds'] = requestParameters.partyIds;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/ammo/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonAmmoLogInfoFromJSON(jsonValue));
    }

    /**
     * getAmmoLogList
     */
    async getAmmoLogListUsingGET(requestParameters: GetAmmoLogListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonAmmoLogInfo> {
        const response = await this.getAmmoLogListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAmmoLog
     */
    async getAmmoLogUsingGETRaw(requestParameters: GetAmmoLogUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonAmmoLog>> {
        if (requestParameters.ammoLogId === null || requestParameters.ammoLogId === undefined) {
            throw new runtime.RequiredError('ammoLogId','Required parameter requestParameters.ammoLogId was null or undefined when calling getAmmoLogUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/ammo/{ammoLogId}`.replace(`{${"ammoLogId"}}`, encodeURIComponent(String(requestParameters.ammoLogId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonAmmoLogFromJSON(jsonValue));
    }

    /**
     * getAmmoLog
     */
    async getAmmoLogUsingGET(requestParameters: GetAmmoLogUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonAmmoLog> {
        const response = await this.getAmmoLogUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveAmmoLog
     */
    async saveAmmoLogUsingPOSTRaw(requestParameters: SaveAmmoLogUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonAmmoLog>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveAmmoLogUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/ammo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonAmmoLogToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonAmmoLogFromJSON(jsonValue));
    }

    /**
     * saveAmmoLog
     */
    async saveAmmoLogUsingPOST(requestParameters: SaveAmmoLogUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonAmmoLog> {
        const response = await this.saveAmmoLogUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
