import {selectCodebooks} from '../store/selectors';
import {useAppSelector} from "../store";
import {getCodebookLabel, getOption, OptionValue} from "../model/form";
import {Typography} from "@mui/material";
import * as React from "react";
import {useMemo} from "react";

export const OptionIcon = ({value, options}: { value: any, options: OptionValue[] }) => {
    const o = getOption(value, options);
    return <Typography component={'div'}
        sx={o.color ? {color: (theme) => theme.palette[o.color || 'primary'].main} : undefined}
        title={o.tooltip}>{o.icon}</Typography>
}

interface Props {
    value: any;
    name: string;
    formatValue?: (value: string) => string;
    scope?: number;
    item?: any;
}

const CodebookValue = ({value, name, scope, formatValue, item}: Props) => {

    const codebooks = useAppSelector(selectCodebooks);
    const position = name === 'group' && item && 'groupPosition' in item ? item.groupPosition : undefined;

    return useMemo(() => {
        const label = getCodebookLabel(codebooks, name, value, scope);

        return <span>{formatValue ? formatValue(label) : label}{position ? <><br/>({position})</> : null}</span>
    }, [value, scope, position, name, formatValue, codebooks]);
}

export default CodebookValue;
