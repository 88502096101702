/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonAmmoLogInfo
 */
export interface JsonAmmoLogInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonAmmoLogInfo
     */
    allowedActions?: Array<JsonAmmoLogInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonAmmoLogInfo
     */
    ammoAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    ammoAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonAmmoLogInfo
     */
    ammoLogId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    ammoLogType?: JsonAmmoLogInfoAmmoLogTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    ammoType?: JsonAmmoLogInfoAmmoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonAmmoLogInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonAmmoLogInfo
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonAmmoLogInfo
     */
    eventPartyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    formatCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonAmmoLogInfo
     */
    partyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    photoGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonAmmoLogInfo
     */
    photoId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    sex?: JsonAmmoLogInfoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    status?: JsonAmmoLogInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonAmmoLogInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonAmmoLogInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonAmmoLogInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonAmmoLogInfoAmmoLogTypeEnum {
    Incoming = 'INCOMING',
    Outgoing = 'OUTGOING'
}/**
* @export
* @enum {string}
*/
export enum JsonAmmoLogInfoAmmoTypeEnum {
    Bullet = 'BULLET'
}/**
* @export
* @enum {string}
*/
export enum JsonAmmoLogInfoSexEnum {
    M = 'M',
    F = 'F'
}/**
* @export
* @enum {string}
*/
export enum JsonAmmoLogInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonAmmoLogInfoFromJSON(json: any): JsonAmmoLogInfo {
    return JsonAmmoLogInfoFromJSONTyped(json, false);
}

export function JsonAmmoLogInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonAmmoLogInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'ammoAmount': !exists(json, 'ammoAmount') ? undefined : json['ammoAmount'],
        'ammoAt': !exists(json, 'ammoAt') ? undefined : json['ammoAt'],
        'ammoLogId': !exists(json, 'ammoLogId') ? undefined : json['ammoLogId'],
        'ammoLogType': !exists(json, 'ammoLogType') ? undefined : json['ammoLogType'],
        'ammoType': !exists(json, 'ammoType') ? undefined : json['ammoType'],
        'birthDate': !exists(json, 'birthDate') ? undefined : json['birthDate'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventPartyId': !exists(json, 'eventPartyId') ? undefined : json['eventPartyId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'formatCode': !exists(json, 'formatCode') ? undefined : json['formatCode'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'photoGuid': !exists(json, 'photoGuid') ? undefined : json['photoGuid'],
        'photoId': !exists(json, 'photoId') ? undefined : json['photoId'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonAmmoLogInfoToJSON(value?: JsonAmmoLogInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'ammoAmount': value.ammoAmount,
        'ammoAt': value.ammoAt,
        'ammoLogId': value.ammoLogId,
        'ammoLogType': value.ammoLogType,
        'ammoType': value.ammoType,
        'birthDate': value.birthDate,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventId': value.eventId,
        'eventPartyId': value.eventPartyId,
        'firstName': value.firstName,
        'formatCode': value.formatCode,
        'lastName': value.lastName,
        'note': value.note,
        'partyId': value.partyId,
        'photoGuid': value.photoGuid,
        'photoId': value.photoId,
        'reason': value.reason,
        'sex': value.sex,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

