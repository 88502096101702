import {Grid} from '@mui/material';
import {FormikErrors} from 'formik';
import {createOption, FormProps, getCodebookLabel, isNumeric, OptionValue} from "../../model/form";
import {
    GetEventPartyListUsingGETPartyTypesEnum,
    JsonAmmoLog,
    JsonAmmoLogAmmoLogTypeEnum,
    JsonAmmoLogAmmoTypeEnum,
    JsonEventPartyInfo,
    JsonPartyInfo
} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import PartyInfoBox from "../party/PartyInfoBox";
import InfoBox from "../layout/InfoBox";
import {ButtonGroupField} from "../form/ButtonGroupField";
import {SelectFormField} from "../form/SelectFormField";
import {fetchEventPartiesOnline} from "../../store/eventParties";
import {getApiResult} from "../../helpers/api";
import {selectCodebooks} from "../../store/selectors";
import {fetchPartyInfo} from "../../store/parties";

export interface AmmoLogFormProps extends FormProps<JsonAmmoLog> {
    party?: JsonPartyInfo,
}

const AmmoLogForm = (props: AmmoLogFormProps) => {
    const {item, party, onSave} = props;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const codebooks = useAppSelector(selectCodebooks);

    const [partyOptions, setPartyOptions] = useState<OptionValue[]>([]);
    const [selectedParty, setSelectedParty] = useState<JsonPartyInfo | undefined>(party);

    const handleSearchParty = useCallback(async (value: string | undefined) => {
        setPartyOptions([]);
        if (!value?.trim()) {
            return;
        }
        const res = await dispatch(fetchEventPartiesOnline({
            eventId: item.eventId,
            search: value.trim(),
            partyTypes: [GetEventPartyListUsingGETPartyTypesEnum.Fo],
            rows: 15,
            orderCol: 'lastName'
        }));
        const options: OptionValue[] = getApiResult<JsonEventPartyInfo[]>(res)?.map(p => ({
            value: '' + p.partyId,
            label: p.fullName + (p.reason ? ' (' + p.reason + ' ' + (p.formatCode || '') + ')' : ''),
            params: {p}
        })) || [];

        setPartyOptions(options.map((o, i) => {
            const other = options.find(x => x.label === o.label);
            if (other && options.indexOf(other) !== i) {
                for (let x of [other, o]) {
                    const p = x.params!.p as JsonEventPartyInfo;
                    const info: string[] = [];
                    if (p.groupId) {
                        info.push(getCodebookLabel(codebooks, 'group', p.groupId));
                    }
                    if (p.email) {
                        info.push(p.email);
                    }
                    if (!info.length) {
                        info.push(String(p.siwiId))
                    }
                    x.label += ' (' + info.join(', ') + ')';
                }
            }
            return o;
        }));

    }, [item.eventId, codebooks, dispatch]);

    const handleSelectParty = useCallback(async (partyId: number) => {
        if (!partyId) {
            setSelectedParty(undefined);
            return;
        }
        setSelectedParty(getApiResult(await dispatch(fetchPartyInfo(partyId))));

    }, [dispatch]);

    const handleSave = useCallback(async (item: JsonAmmoLog) => {
        const data: JsonAmmoLog = {
            ammoType: JsonAmmoLogAmmoTypeEnum.Bullet,
            ...item
        };

        if (onSave) {
            return onSave(data);
        }

    }, [onSave]);


    const validate = useCallback((values: JsonAmmoLog) => {
        const errors = {} as FormikErrors<JsonAmmoLog>;
        if (!values.partyId) {
            errors.partyId = t('Zvolte osobu')
        }
        if (!values.ammoAt) {
            errors.ammoAt = t('Zadejte datum a čas')
        }
        if (!values.ammoLogType) {
            errors.ammoLogType = t('Zvolte typ')
        }
        if (!values.ammoAmount || !isNumeric(values.ammoAmount, true)) {
            errors.ammoAmount = t('Zadejte prosím celé číslo')
        } else if (values.ammoAmount < 0) {
            errors.ammoAmount = t('Zadejte prosím kladné číslo')
        }
        return errors;
    }, [t]);

    const typeOptions = useMemo(() => {
        return [
            createOption(JsonAmmoLogAmmoLogTypeEnum.Incoming, t('Přineseno'), undefined, undefined, 'success'),
            createOption(JsonAmmoLogAmmoLogTypeEnum.Outgoing, t('Odneseno'), undefined, undefined, 'error'),
        ]
    }, [t]);

    return (
        <FormContainer {...props} validate={validate} onSave={handleSave} children={({setFieldValue}) => <>
            <Grid item xs={12}>
                {selectedParty
                    ?
                    <PartyInfoBox party={selectedParty} simple eventId={item.eventId} actions={item.apiLog ? undefined : [{
                        title: 'Změnit osobu',
                        color: 'secondary',
                        action: () => {
                            setFieldValue('partyId', undefined, true);
                            setSelectedParty(undefined);
                        }
                    }]}/>
                    : <SelectFormField name={'partyId'} label={'Osoba'} placeholder={'Jméno, email, ...'}
                        options={partyOptions}
                        onSearch={handleSearchParty}
                        onChange={handleSelectParty}
                    />}
            </Grid>
            <Grid item xs={12}>
                <InfoBox>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <TextFormField name="ammoAt" label={t('Datum a čas')} type={'datetime-local'} maxValue={new Date()}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField name="ammoAmount" label={t('Množství')} type={'text'} maxlength={6}/>
                        </Grid>
                        <Grid item xs={4} sx={{
                            '.MuiFormControl-root': {
                                marginTop: '10px',
                                '.MuiButtonBase-root.MuiButton-root': {padding: '7px'}
                            }
                        }}>
                            <ButtonGroupField name={'ammoLogType'} options={typeOptions}/>
                        </Grid>
                    </Grid>
                </InfoBox>
            </Grid>
        </>}/>
    );
}

export default AmmoLogForm;
