import {createAsyncThunk,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {
    ApiControllerApi, ApiResultOfJsonApiEventParty, ApiResultOfJsonApiLog,
    ApiResultOfJsonFile,
    ApiResultOfJsonInviteReply,
    ApiResultOfJsonRsvp, ApiResultOfJsonRsvpFloorDayPlan,
    FindPartyUsingPOSTRequest, GetEventPartyByBarcodeUsingGETRequest,
    GetRsvpEventFloorDayPlanUsingGETRequest,
    GetRsvpUsingGETRequest,
    JsonInviteReply,
    RsvpControllerApi, UpdateApiLogUsingPOSTRequest
} from '../generated-api';

const rsvpsApi = new RsvpControllerApi(API_CONFIG);
const api = new ApiControllerApi(API_CONFIG);

export const fetchRsvp = createAsyncThunk('rsvp/fetch', async (request: GetRsvpUsingGETRequest): Promise<ApiResultOfJsonRsvp> => {
    return rsvpsApi.getRsvpUsingGET(request);
});

export const saveRsvp = createAsyncThunk('rsvp/save', async (rsvp: { token: string, reply: JsonInviteReply, isSend?: boolean }): Promise<ApiResultOfJsonInviteReply> => {
    return await rsvpsApi.saveRsvpReplyUsingPOST({...rsvp, json: rsvp.reply});
});

export const saveRsvpFile = createAsyncThunk('rsvp/file', async (rsvp: { token: string, file: File }): Promise<ApiResultOfJsonFile> => {
    return await rsvpsApi.saveRsvpFileUsingPOST({token: rsvp.token, photo: rsvp.file});
});

export const saveRsvpFileTravel = createAsyncThunk('rsvp/fileTravel', async (rsvp: { token: string, file: File }): Promise<ApiResultOfJsonFile> => {
    return await rsvpsApi.saveRsvpFileTravelUsingPOST({token: rsvp.token, attachment: rsvp.file});
});

export const findRsvpParty = createAsyncThunk('rsvp/findParty', async (request: FindPartyUsingPOSTRequest, thunkApi) => {
    return await rsvpsApi.findPartyUsingPOST(request);
});

export const fetchRsvpFloorDayPlan = createAsyncThunk('rsvp/fetch', async (request: GetRsvpEventFloorDayPlanUsingGETRequest): Promise<ApiResultOfJsonRsvpFloorDayPlan> => {
    return rsvpsApi.getRsvpEventFloorDayPlanUsingGET(request);
});

export const fetchEventPartyByBarcode = createAsyncThunk('api/event-party-barcode', async (request: GetEventPartyByBarcodeUsingGETRequest): Promise<ApiResultOfJsonApiEventParty> => {
    return api.getEventPartyByBarcodeUsingGET(request);
});

export const updateApiLog = createAsyncThunk('api/update', async (request: UpdateApiLogUsingPOSTRequest): Promise<ApiResultOfJsonApiLog> => {
    return api.updateApiLogUsingPOST(request);
});
