import {
    JsonArticle,
    JsonEventPartyDayDetails,
    JsonEventPartyInfo,
    JsonEventPartyTravel,
    JsonInviteInfo,
    JsonInviteInfoInviteTypeEnum,
    JsonInviteInfoStatusEnum,
    JsonInviteReply,
    JsonInviteReplyDay,
    JsonInviteReplyDayReplyTypeEnum,
    JsonInviteReplyExtra,
    JsonInviteReplyReplyTypeEnum,
    JsonPlaceFoodServicesEnum,
    JsonReplyPartyData,
    JsonRsvp
} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import {Alert, Box, Card, CardContent, Grid} from "@mui/material";
import {getLink, renderFile, renderPaymentConfirmationFile} from "../../helpers/files";
import placeholder from "../../assets/images/placeholder.png";
import * as React from "react";
import RsvpInvitePartyBox from "./RsvpInvitePartyBox";
import {dayDate} from "../../model/invite";
import {renderAddress} from "../../helpers/format";
import {dateToGui, dateToGuiAs} from "../../helpers/date";
import {formatPhone} from "../../helpers/formfield";
import {FAKE_VALUE_EMPTY} from "../../model/form";
import {localCompare} from "../DataGrid";
import {foodServiceOptions, foodServiceProps} from "../../model/place";
import {TravelInfo} from "../TravelInfo";
import {RsvpInviteComp} from "./RsvpInviteComp";
import {PartyCompDetails} from "../party/PartyCompDetails";
import {articleName} from "../../model/party";


type RsvpInviteSummaryProps = {
    values: JsonInviteReply,
    rsvp: JsonRsvp,
}

const RsvpInviteSummary = (props: RsvpInviteSummaryProps) => {
    const {values, rsvp} = props;
    const {invite, eventDays, eventParties, contract, tariffs, event} = rsvp;

    const t = useAppTranslation();

    if (!invite) {
        return null;
    }

    const isConfirmed = invite.status === JsonInviteInfoStatusEnum.Confirmed;

    const currentData: {
        owner?: JsonEventPartyInfo | JsonInviteInfo,
        eventParty?: JsonEventPartyInfo,
        replyDays?: { [key in number]: JsonInviteReplyDay },
        extras?: JsonInviteReplyExtra[],
        articles?: (JsonArticle & { isReceived?: boolean })[],
        partyData?: JsonReplyPartyData,
        eventDays?: JsonEventPartyDayDetails[],
        travels?: JsonEventPartyTravel[],
        isAcm?: boolean
    } = {};
    const hasFoodServiceTypes: JsonPlaceFoodServicesEnum[] = [];
    let isAcm = false, isParking = false;
    if (isConfirmed && eventParties) {
        const owner = eventParties[0];
        currentData.owner = owner;
        currentData.eventParty = owner;
        if (invite.inviteType === JsonInviteInfoInviteTypeEnum.Guest) {
            currentData.replyDays = {};
            eventDays?.forEach((ed) => {
                if (!ed.dayNo || !currentData.replyDays) {
                    return;
                }
                currentData.replyDays[ed.dayNo] = {
                    dayNo: ed.dayNo,
                    replyType: owner.eventDays?.[(ed.dayNo || 0) - 1] === '1'
                        ? JsonInviteReplyDayReplyTypeEnum.Accept
                        : JsonInviteReplyDayReplyTypeEnum.Reject
                }
            });
        }
        currentData.extras = eventParties.filter(ep => ep !== owner).map((ep) => {
            return {
                ...ep,
                extraId: ep.partyId,
                eventDayNos: ep.eventDays?.split('').map((d, i) => d === '1' ? (i + 1) : 0).filter(d => d !== 0).map((d, i) => d) // 0101 => [2, 4]
            }
        }) as JsonInviteReplyExtra[];
        currentData.articles = owner.eventArticleDetails?.map((epa) => {
            return {
                articleTypeId: epa.articleTypeId,
                articleId: epa.recArticleId || epa.prefArticleId,
                isReceived: !!epa.recArticleId
            }
        });
        currentData.partyData = {...owner}
        if (invite.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg) {
            currentData.eventDays = owner.eventDayDetails;
            Object.keys(foodServiceProps).forEach(k => {
                const serviceType = k as JsonPlaceFoodServicesEnum;
                if (currentData.eventDays?.find((epd) => epd[foodServiceProps[serviceType]])) {
                    hasFoodServiceTypes.push(serviceType);
                }
            })
            isAcm = !!currentData.eventDays?.find((epd) => !!epd.acmId);
            isParking = !!currentData.eventDays?.find((epd) => !!epd.parkId);
        }
    } else {
        currentData.owner = invite;
        currentData.eventParty = eventParties?.[0];
        currentData.replyDays = values.replyData?.replyDays;
        eventDays?.forEach((ed) => {
            if (!ed.dayNo || !currentData.replyDays) {
                return;
            }
            if (!currentData.replyDays[ed.dayNo]?.dayNo) {
                // if day was added while reply already existed, day would be empty
                currentData.replyDays[ed.dayNo] = {...currentData.replyDays[ed.dayNo], dayNo: ed.dayNo};
            }
        });
        currentData.extras = values.replyData?.extras;
        currentData.articles = values.replyData?.articles;
        currentData.partyData = values.replyData?.partyData;
        currentData.travels = values.replyData?.travels;
        currentData.isAcm = values.replyData?.isAcm;
    }

    return <div className={'rsvp rsvp-summary'}>
        {invite.inviteType !== JsonInviteInfoInviteTypeEnum.TravelOrg && invite.inviteType !== JsonInviteInfoInviteTypeEnum.CompOrg &&
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item sm={2} xs={3} className={'rsvp-head'}>
                            <p>{t('Údaje o Vás')}</p>
                        </Grid>
                        {isConfirmed
                            ? <Grid item sm={10} xs={9} sx={{paddingLeft: '10px'}}>
                                <Grid container spacing={2} sx={{marginTop: '-10px'}}>
                                    <Grid item md={4} sm={6} xs={12}>
                                        <RsvpInvitePartyBox extra={currentData.owner}/>
                                    </Grid>
                                    {invite.inviteId === FAKE_VALUE_EMPTY && invite.groupTitle &&
                                        <Grid item md={8} sm={6} xs={12}>
                                            {t('Pracovní skupina') + ': '}<strong>{invite.groupTitle}</strong>
                                            {!!invite.groupPosition && <div style={{marginTop: '10px'}}>
                                                {t('Pozice') + ': '}<strong>{invite.groupPosition}</strong>
                                            </div>}
                                        </Grid>}
                                </Grid>
                            </Grid>
                            : <Grid item sm={10} xs={8}>
                                <Grid container className={'rsvp-info'}>
                                    <Grid item sm={8} xs={12}>
                                        <p><strong>{values.newFirstName}<br/>{values.newLastName}</strong></p>
                                        <p>{values.infoIsValid || (values.newFirstName === invite.firstName && values.newLastName === invite.lastName)
                                            ? t('Jméno a příjmení byly ponechány beze změn.')
                                            : t('Jméno a příjmení údaje byly upraveny.')}
                                        </p>
                                        <p>{!values.newPhotoGuid || values.newPhotoGuid === invite.photoGuid
                                            ? t('Fotografie byla ponechána původní.')
                                            : !invite.photoGuid ? t('Byla nahrána fotografie.') : t('Fotografie byla upravena.')}
                                        </p>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <Box
                                            component="img"
                                            alt={t('Fotografie')}
                                            src={values.newPhotoGuid || invite.photoGuid ? getLink(values.newPhotoGuid || invite.photoGuid) : placeholder}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>}
                    </Grid>
                </CardContent>
            </Card>}

        {eventDays?.map((ed) => {
            const rd = ed.dayNo ? currentData.replyDays?.[ed.dayNo] : undefined;
            if (!rd?.dayNo || !ed?.dayNo) {
                return null;
            }
            const park = isConfirmed ? eventParties?.[0]?.eventDayDetails?.find((edd) => edd.dayNo === ed.dayNo) : undefined;
            const parking = !!park?.parkId
                ? <span>{t(' a máte přiřazeno parkoviště')}
                    <strong>{rsvp.codebooks?.['placePark:' + park.parkId]}</strong>{park.parkCnt && park.parkCnt > 1 ? ' (' + park.parkCnt + 'x)' : null}</span>
                : (!!invite.inviteData?.isPark && !isConfirmed
                    ? rd.isParking
                        ? t(' a máte zájem o parkování')
                        : t(' a nemáte zájem o parkování')
                    : '');
            const extras = currentData.extras?.filter((e) => e.eventDayNos && ed.dayNo && e.eventDayNos.indexOf(ed.dayNo) >= 0) || [];

            return <Card key={rd.dayNo} className={'rsvp-day rsvp-day-' + rd.replyType}>
                <CardContent>
                    <Grid container>
                        <Grid item sm={2} xs={3} className={'rsvp-day-date'}>
                            {dayDate(ed)}
                        </Grid>
                        <Grid item sm={10} xs={9} className={'rsvp-day-info'}>
                            {ed.description && <p className={'rsvp-day-desc'}>{ed.description}</p>}
                            {rd.replyType === JsonInviteReplyDayReplyTypeEnum.Accept
                                ? !!invite.inviteData?.extraLimit && invite.inviteData.extraLimit > 0
                                    ? (extras.length > 0)
                                        ? <>
                                            <p>{t('Dorazíte s doprovodem')}{parking}:</p>
                                            <Grid container spacing={2} sx={{marginTop: '-10px'}}>
                                                {extras?.map((extra, j) => {
                                                    if (!extra) {
                                                        return null;
                                                    }
                                                    return <Grid key={j} item md={4} sm={6} xs={12}>
                                                        <RsvpInvitePartyBox extra={extra}/>
                                                    </Grid>
                                                })}
                                            </Grid>
                                        </>
                                        : <p>{t('Dorazíte bez doprovodu')}{parking}.</p>
                                    : <p>{t('Tento den dorazíte')}{parking}.</p>
                                : <em>{t('Tento den nedorazíte.')}</em>}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        })}

        {invite.inviteType === JsonInviteInfoInviteTypeEnum.Supplier && <Card>
            <CardContent>
                {!isConfirmed && <div>{t('Celkem zadáno osob personálu: {{count}} (z toho nových: {{added}})', {
                    count: currentData.extras?.length,
                    added: currentData.extras?.filter((e) => e.partyId === FAKE_VALUE_EMPTY)?.length,
                })}</div>}
                <Grid container spacing={2} sx={{marginTop: '-10px'}}>
                    {currentData.extras?.slice()
                        ?.sort((a, b) => localCompare(a.lastName + ' ' + b.firstName, b.lastName + ' ' + b.firstName))
                        .map((extra, j) => {
                            if (!extra) {
                                return null;
                            }
                            return <Grid key={j} item md={4} sm={6} xs={12}>
                                <RsvpInvitePartyBox extra={extra}/>
                            </Grid>
                        })}
                </Grid>
            </CardContent>
        </Card>}

        {!!invite.inviteData?.articleTypes && invite.inviteData.articleTypes.length > 0 && <Card>
            <CardContent>
                <Grid container>
                    <Grid item sm={2} xs={3} className={'rsvp-head'}>
                        <p>{t('Artikly')}</p>
                    </Grid>
                    <Grid item sm={10} xs={9}>
                        <table className={'rsvp-simple-table'}>
                            <tbody>
                            {invite.inviteData.articleTypes.map((at, i) => {
                                const pa = currentData.articles?.find((pa) => pa.articleTypeId === at.articleTypeId);
                                const a = invite?.inviteData?.articles?.find((a) => a.articleTypeId === at.articleTypeId && a.articleId === pa?.articleId);
                                if (!!at.variants?.isExtra && !a) {
                                    return null;
                                }
                                return <tr key={i}>
                                    <td>{at.title}</td>
                                    <th>{articleName(a, true, invite.sex)}</th>
                                    <td>{!!pa?.isReceived && <p>{t('(vydáno)')}</p>}</td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>}

        {invite.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg && currentData.eventDays?.length && <Card>
            <CardContent>
                <Grid container>
                    <Grid item sm={2} xs={3} className={'rsvp-head'}>
                        <p>{t('Dny události')}</p>
                    </Grid>
                    <Grid item sm={10} xs={9}>
                        <table className={'rsvp-simple-table rsvp-simple-table-grid'}>
                            <thead>
                            <tr>
                                <th style={{width: '20%'}}>{t('Den')}</th>
                                {!!hasFoodServiceTypes && hasFoodServiceTypes.map((serviceType, i) => <th key={i}
                                    style={{width: Math.round((40 + (isAcm ? 0 : 20) + (isParking ? 0 : 20)) / hasFoodServiceTypes.length) + '%'}}>
                                    {foodServiceOptions.find(o => o.value === serviceType)?.tooltip}</th>)}
                                {isAcm && <th style={{width: '20%'}}>{t('Ubytování')}</th>}
                                {isParking && <th style={{width: '20%'}}>{t('Parkování')}</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {eventDays?.map((ed, i) => {
                                const epd = currentData.eventDays?.find((epd) => ed.dayNo === epd.dayNo);
                                if (!epd) {
                                    return null;
                                }
                                const foodServices: JSX.Element[] = [];
                                hasFoodServiceTypes.forEach((serviceType) => {
                                    const foodProp = foodServiceProps[serviceType];
                                    foodServices.push(<th key={serviceType}>
                                        {!!epd[foodProp] && rsvp.codebooks?.['placeFood:' + epd[foodProp]]}
                                    </th>)
                                });

                                return <tr key={i}>
                                    <td>{ed ? dateToGuiAs(ed.dayDate, 'eeeee d. M.') : epd.dayNo}</td>
                                    {!!hasFoodServiceTypes && <>{foodServices}</>}
                                    {isAcm && <th>{epd.acmId
                                        ? rsvp.codebooks?.['placeAcm:' + epd.acmId]
                                        : null}</th>}
                                    {isParking && <th>{epd.parkId
                                        ? <>{rsvp.codebooks?.['placePark:' + epd.parkId]} {(epd.parkCnt || 0) > 1 ? '(' + epd.parkCnt + 'x)' : ''}</>
                                        : null}</th>}
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>}

        {!!(currentData.eventParty?.eventTravelDetails || currentData.eventParty?.eventCompDetails) && invite.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg &&
            <Card>
                <CardContent>
                    <PartyCompDetails eventParty={currentData.eventParty} tariffs={tariffs}/>
                </CardContent>
            </Card>}

        {invite.inviteType === JsonInviteInfoInviteTypeEnum.Organizer && <Card>
            <CardContent>
                <Grid container>
                    <Grid item sm={2} xs={12} className={'rsvp-head'}>
                        <p>{t('Ubytování')}</p>

                    </Grid>
                    <Grid item sm={10} xs={12}>
                        {currentData.isAcm ? t('Máte zájem o zajištění ubytování') : t('Nemáte zájem o zajištění ubytování')}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>}

        {(invite.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg || invite.inviteType === JsonInviteInfoInviteTypeEnum.Organizer) &&
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item sm={2} xs={12} className={'rsvp-head'}>
                            <p>{t('Další informace')}</p>

                        </Grid>
                        <Grid item sm={10} xs={12}>
                            <table className={'rsvp-simple-table'}>
                                <tbody>
                                <tr>
                                    <td>{t('Bankovní účet')}</td>
                                    <th>{currentData.partyData?.bankAccount}</th>
                                </tr>
                                <tr>
                                    <td>{t('Datum narození')}</td>
                                    <th>{dateToGui(currentData.partyData?.birthDate)}</th>
                                </tr>
                                <tr>
                                    <td>{t('Telefon')}</td>
                                    <th>{formatPhone(currentData.partyData?.phone)}</th>
                                </tr>
                                <tr>
                                    <td>{t('Trvalé bydliště')}</td>
                                    <th>{renderAddress(currentData.partyData?.permAddress)}</th>
                                </tr>
                                {!!contract && <tr>
                                    <td>{t('Smlouva')}</td>
                                    <th>{renderFile(contract)}</th>
                                </tr>}
                                {!!currentData.eventParty?.eventCompDetails && <tr>
                                    <td>{t('Potvrzení příjmy')}</td>
                                    <th>{renderPaymentConfirmationFile((currentData.owner as JsonEventPartyInfo).eventPartyGuid, event?.eventFrom?.substring(0, 4))}</th>
                                </tr>}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>}

        {invite.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg && <Card>
            <CardContent>
                {!isConfirmed && <div>{t('Celkem zadáno cest: {{count}}', {
                    count: currentData.travels?.length,
                })}</div>}
                <Grid container spacing={2} sx={{marginTop: '-10px'}}>
                    {currentData.travels
                        ?.map((travel, j) => {
                            if (!travel) {
                                return null;
                            }
                            return <Grid key={j} item md={6} xs={12}>
                                <Card sx={{height: '100%'}}>
                                    <CardContent>
                                        <TravelInfo travel={travel}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                        })}
                </Grid>
            </CardContent>
        </Card>}

        {invite.inviteType === JsonInviteInfoInviteTypeEnum.CompOrg && !!currentData.eventParty && <>
            <RsvpInviteComp values={values} eventParty={currentData.eventParty} tariffs={rsvp.tariffs} readonly/>
            {values.replyType === JsonInviteReplyReplyTypeEnum.Reject && <Card>
                <Alert severity={'warning'}>
                    <pre>{values.replyNote}</pre>
                </Alert>
            </Card>}
        </>}

        {invite.inviteType !== JsonInviteInfoInviteTypeEnum.UpdateOrg && invite.inviteType !== JsonInviteInfoInviteTypeEnum.CompOrg &&
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item sm={2} xs={3} className={'rsvp-head'}>
                            <span>{t('Poznámka')}</span>
                        </Grid>
                        <Grid item sm={10} xs={9}>
                            {values.replyNote
                                ? <pre>{values.replyNote}</pre>
                                : <em>{t('Nevyplněna')}</em>}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>}

    </div>
}

export default RsvpInviteSummary;
