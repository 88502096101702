import {Container, Grid, Link} from '@mui/material';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {TAppFunction, useAppTranslation} from '../services/i18n';
import {clearInvites, fetchInvites, fetchInvitesCount} from '../store/invites';
import {Link as RouterLink} from "react-router-dom";
import PageHeader from '../components/layout/PageHeader';
import {
    GetInviteCountUsingGETRequest,
    GetInviteListUsingGETInviteKindsEnum,
    GetInviteListUsingGETInviteTypesEnum,
    GetInviteListUsingGETRequest,
    JsonAuthUserInfoRightsEnum,
    JsonInviteInfo,
    JsonInviteInfoInviteTypeEnum,
    JsonInviteInfoStatusEnum,
    JsonInviteReply,
    JsonInviteReplyStatusEnum
} from '../generated-api';
import {
    createCol,
    DataGrid,
    DataGridCol,
    DataGridFilter,
    DataGridFilterProps,
    DataGridItemAction,
    DataGridState,
    defaultDataGridFilterState
} from "../components/DataGrid";
import {useAppDispatch, useAppSelector} from "../store";
import {selectAuthInfo, selectInvites} from "../store/selectors";
import {TextFormField} from "../components/form/TextFormField";
import {ButtonGroupField} from "../components/form/ButtonGroupField";
import {SelectFormField} from "../components/form/SelectFormField";
import CodebookValue, {OptionIcon} from "../components/CodebookValue";
import {datetimeToGui, dateToGuiAs} from "../helpers/date";
import {DoneOutlineRounded, HourglassEmptyRounded, QuestionMarkRounded, RemoveRedEye} from "@mui/icons-material";
import InviteReviewModal from "./InviteReviewModal";
import {saveInviteReply} from "../store/inviteReplies";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../helpers/api";
import {
    inviteIsActiveOptions,
    renderInviteType,
    updateInviteStatusOptions,
    updateInviteTypeOptions
} from "../model/invite";
import {useNavigate} from "react-router";
import {FIXED_INVITE_TAG_ID} from "../store/codebooks";
import PartyTags from "../components/PartyTags";
import {ReplyExtra} from "./InvitesPage";
import {AuthState} from "../store/auth";

interface InvitesGridFilter extends DataGridFilter, GetInviteListUsingGETRequest {
}

interface InvitesGridState extends DataGridState<JsonInviteInfo, InvitesGridFilter> {
}

export const shortDate = (v?: string) => {
    return <span title={datetimeToGui(v)}>{dateToGuiAs(v, 'd.M.')}</span>
}

export const createInviteActions = (t: TAppFunction, setInviteId: (inviteId?: number) => void, user: AuthState['user'], rights: AuthState['rights']) => {
    const isAdmin = rights.includes(JsonAuthUserInfoRightsEnum.Admin);
    return [
        {
            title: t('Zpracovat'),
            icon: <DoneOutlineRounded/>,
            callback: (item) => setInviteId(item.inviteId),
            isApplicable: (item) => {
                return item.status === JsonInviteInfoStatusEnum.Accepted
                    && (isAdmin || (user?.userData?.groupIds && item.groupId && user.userData.groupIds.indexOf(item.groupId) >= 0));
            }
        }, {
            title: t('Náhled'),
            icon: <RemoveRedEye/>,
            callback: (item) => setInviteId(item.inviteId),
            isApplicable: (item) => {
                return item.status !== JsonInviteInfoStatusEnum.Accepted
                    && (isAdmin || (user?.userData?.groupIds && item.groupId && user.userData.groupIds.indexOf(item.groupId) >= 0));
            }
        }
    ] as DataGridItemAction<JsonInviteInfo>[];
}


const cols: DataGridCol<JsonInviteInfo, InvitesGridFilter>[] = [
    createCol('Kód', 'token', 40, 'Kód výzvy a odkaz na formulář', (v) => <pre><a href={'/rsvp/' + v} target={'_blank'} rel="noreferrer">{v}</a></pre>),
    createCol('Typ', 'inviteType', '40C', 'Typ výzvy', renderInviteType),
    createCol('Jméno', 'fullName', 100, undefined, (v, item) => <Link underline={'hover'} to={`/parties/${item.partyId}`} component={RouterLink}>{v}</Link>),
    createCol('Email', 'email', 130, undefined, (v) => <small>{v}</small>),
    createCol('Štítky / Firma', 'tags', 100, undefined, (v, item) => {
        const items = [];
        if (v) {
            items.push(<PartyTags key={'tags'} tags={v} partyId={item.partyId} quickEditTagId={FIXED_INVITE_TAG_ID}/>);
        }
        if (item.companyId) {
            items.push(<small style={v ? {display: 'inline-block', marginTop: '10px'} : undefined} key={'company'}><CodebookValue value={item.companyId} name={'company'}/></small>)
        }
        return items;
    }),
    createCol('Skupina', 'groupId', 80, 'Pracovní skupina', (v, item) => v ? <CodebookValue value={v} name={'group'}/> : null),
    {
        title: 'Osoby / Náhrady',
        size: 80,
        col: 'replyData',
        renderValue: (v, item, filter) => {
            return item.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg
                ? (!!item.replyData?.travels?.length ? <span>{item.replyData?.travels?.length}</span> : null)
                : <ReplyExtra item={item}/>
        }
    },
    createCol('Ode', 'createdAt', 40, 'Vytvoření výzvy', shortDate),
    createCol('Odp', 'replyAt', 40, 'Odpověď přijatá', shortDate),
    createCol('Uzá', 'replyUntil', 40, 'Odpověď do', shortDate),
    createCol('Zpr', 'processAt', 40, 'Odpověď zpracovaná', shortDate),
    // createCol('N', 'isActive', 10, 'Výzva je neaktivní, tj. pro danou osobu existuje novější', (v) => !v ? <Done/> : null),
    createCol('St', 'status', 30, 'Stav výzvy', (v) => {
        return <OptionIcon value={v} options={updateInviteStatusOptions}/>
    }),
];

const defaultStateTemplate: InvitesGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'createdAt',
        orderDir: 'desc',
        search: '',
        partyIds: [],
        eventId: -1,
        eventDay: undefined,
        inviteTypes: [],
        groupIds: [],
        extraLimit: undefined,
        isPark: undefined,
        companyIds: [],
        isActive: undefined,
        relPartyId: undefined,
        statuses: [],
        inviteKinds: [GetInviteListUsingGETInviteKindsEnum.Update, GetInviteListUsingGETInviteKindsEnum.Travel, GetInviteListUsingGETInviteKindsEnum.Comp]
    },
};

export const getInviteRowClassName = (item: JsonInviteInfo, filter?: InvitesGridFilter): string[] | undefined => {
    if (!item.isActive) {
        return ['data-grid-inactive-row'];
    }
    return undefined;
}

const filterFields = (props: DataGridFilterProps<InvitesGridFilter>, t: TAppFunction): JSX.Element => {
    const {formProps, buttons, user, rights} = props;
    const isAdmin = rights.includes(JsonAuthUserInfoRightsEnum.Admin);

    return <Grid container spacing={1} columns={24}>
        <Grid item xs={3}>
            <SelectFormField name="eventId" placeholder={'Událost'} onChange={formProps.submitForm}
                codebookProps={{codebookName: 'event', asNumber: true, sortByKeys: true, reversed: true}}/>
        </Grid>
        <Grid item xs={3}>
            <TextFormField name="search" type={'text'} placeholder={'Jméno, email, ...'} onBlur={formProps.submitForm} clearable/>
        </Grid>
        <Grid item xs={5}>
            {isAdmin && <ButtonGroupField name="inviteTypes" label={'Typ výzvy'} onChange={formProps.submitForm}
				options={updateInviteTypeOptions} isMulti fullWidth/>}
        </Grid>
        <Grid item xs={3}>
            <SelectFormField name="groupIds" placeholder={'Skupina'} onChange={formProps.submitForm}
                codebookProps={{
                    codebookName: 'group', asNumber: true,
                    addEmpty: t('(bez skupiny)'),
                    allowedValues: isAdmin ? undefined : user?.userData?.groupIds
                }} isMulti={true}/>
        </Grid>
        <Grid item xs={3}>
            <SelectFormField name="companyIds" placeholder={'Firma'} onChange={formProps.submitForm}
                codebookProps={{codebookName: 'company', asNumber: true, addEmpty: t('(bez firmy)')}} isMulti={true}/>
        </Grid>
        <Grid item xs={2}>
            <ButtonGroupField name="isActive" label={'Aktuální = poslední verze, pokud jich existuje více'} onChange={formProps.submitForm}
                options={inviteIsActiveOptions} fullWidth/>
        </Grid>
        <Grid item xs={3}>
            <ButtonGroupField name="statuses" label={'Stav výzvy'} onChange={formProps.submitForm}
                options={updateInviteStatusOptions} isMulti iconsOnly fullWidth/>
        </Grid>
        <Grid item xs={2}>
            {buttons}
        </Grid>
    </Grid>
}

const UpdateInvitesPage = () => {
    const t = useAppTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {user, configuration, rights} = useAppSelector<AuthState>(selectAuthInfo);
    const isAdmin = rights.includes(JsonAuthUserInfoRightsEnum.Admin);

    const defaultState = useRef<InvitesGridState>({...defaultStateTemplate, filter: {...defaultStateTemplate.filter, eventId: configuration?.defaultEvent?.eventId}});
    const [inviteId, setInviteId] = useState<number | undefined>(undefined);
    const [currentFilter, setCurrentFilter] = useState<InvitesGridFilter>(defaultState.current.filter);

    const invites = useAppSelector(selectInvites);

    const handleFetchItems = useCallback((filter: InvitesGridFilter) => {
        setCurrentFilter(filter);
        dispatch(fetchInvitesCount(filter as any as GetInviteCountUsingGETRequest)).then((r) => {
            dispatch(fetchInvites(filter));
        });

    }, [dispatch]);

    const handleSaveReply = useCallback(async (reply: JsonInviteReply) => {
        const res = await dispatch(saveInviteReply(reply));
        const item = getApiResult<JsonInviteReply>(res);
        if (item) {
            let m: string = reply.status + '';
            switch (reply.status) {
                case JsonInviteReplyStatusEnum.Accepted:
                    m = 'Výzva byla schválena';
                    break;
                case JsonInviteReplyStatusEnum.Rejected:
                    m = 'Výzva byla vrácena k doplnění';
                    break;
                case JsonInviteReplyStatusEnum.Deleted:
                    m = 'Výzva byla stornována';
                    break;
                case JsonInviteReplyStatusEnum.Sent:
                    m = 'Rozpracovaná výzva uložena';
                    break;
            }
            addApiResultMessage(res, {
                [ApiChangeType.CREATED]: m,
                [ApiChangeType.UPDATED]: m,
                [ApiChangeType.NO_CHANGE]: m,
            }, t, dispatch);
            if (reply.status === JsonInviteReplyStatusEnum.Sent) {
                return item;
            }
            handleFetchItems(currentFilter);
            setInviteId(undefined);
        }
        return item;

    }, [handleFetchItems, currentFilter, dispatch, t]);

    const actions = useMemo(() => {
        return createInviteActions(t, setInviteId, user, rights)
    }, [setInviteId, user, rights, t]);

    const defaultStateScoped: InvitesGridState = useMemo(() => {
        return {
            ...defaultState.current,
            filter: {
                ...defaultState.current.filter,
                inviteTypes: isAdmin ? defaultState.current.filter.inviteTypes : [GetInviteListUsingGETInviteTypesEnum.TravelOrg]
            }
        }
    }, [isAdmin]);

    useEffect(() => {
        return () => {
            dispatch(clearInvites());
        }
    }, [dispatch]);

    return (
        <Container>
            <PageHeader title={t('Výzvy')}
                subPages={[{
                    icon: <HourglassEmptyRounded/>,
                    title: t('Ke schválení'),
                    action: () => {
                        navigate('/update-invites?isActive=true&statuses=ACCEPTED');
                    }
                }, {
                    icon: <QuestionMarkRounded/>,
                    title: t('Nezodpovězené'),
                    action: () => {
                        navigate('/update-invites?isActive=true&statuses=PENDING&statuses=RETURNED');
                    }
                }]}
            />

            <DataGrid
                cols={cols}
                defaultState={defaultStateScoped}
                handleFetchItems={handleFetchItems}
                itemsState={invites}
                filterFields={filterFields}
                actions={actions}
                itemKey={'inviteId'}
                getRowClassNames={getInviteRowClassName}
            />

            {!!inviteId && <InviteReviewModal
				inviteId={inviteId}
				onSave={handleSaveReply}
				onCancel={() => setInviteId(undefined)}/>}
        </Container>
    );
}

export default UpdateInvitesPage;
