import {
    createCol,
    DataGrid,
    DataGridCol,
    DataGridFilter,
    DataGridItemAction,
    DataGridMode,
    DataGridState,
    defaultDataGridFilterState,
    OrderDirType
} from "../DataGrid";
import {
    GetAmmoLogListUsingGETRequest,
    JsonAmmoLog,
    JsonAmmoLogInfo,
    JsonAmmoLogInfoAmmoLogTypeEnum,
    JsonAmmoLogInfoStatusEnum,
    JsonAmmoLogStatusEnum,
    JsonApiLog,
    JsonApiLogAmmoStatusEnum,
    JsonEventInfo,
    JsonEventPartyInfo,
    JsonPartyEventDetails,
    JsonPartyInfo,
    JsonPartyInfoPartyTypeEnum,
    JsonWsEventItem,
    JsonWsMessage,
    JsonWsMessageTypeEnum
} from "../../generated-api";
import {createDefaultListState, ItemsState, useAppDispatch} from "../../store";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {fetchAmmoLog, fetchAmmoLogs, saveAmmoLog} from "../../store/eventAmmoLog";
import {
    ArrowDownwardOutlined,
    ArrowUpwardOutlined,
    DoneRounded,
    Edit,
    PostAddOutlined,
    RefreshOutlined
} from "@mui/icons-material";
import EventAmmoLogModal from "../../pages/EventAmmoLogModal";
import {useAppTranslation} from "../../services/i18n";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../../helpers/api";
import {Alert, Button, ButtonGroup, Grid, LinearProgress, Link} from "@mui/material";
import SockJS from 'sockjs-client';
import {Client} from "stompjs";
import {apiBasePath} from "../../app/config";
import {AuthState} from "../../store/auth";
import {Link as RouterLink} from "react-router-dom";
import {partyName} from "../../model/party";
import {dateToGuiAs, normalizeDatetime} from "../../helpers/date";
import PartyPhoto, {PartyPhotoTooltip} from "../party/PartyPhoto";
import EventPartyAccredDetail from "../EventPartyAccredDetail";
import {AmmoLogFormProps} from "./EventAmmoLogForm";
import InfoBox, {InfoBoxProps} from "../layout/InfoBox";
import {AmmoScanner} from "../../pages/AmmoPage";
import {updateApiLog} from "../../store/rsvp";
import {formatDecimal} from "../../model/form";
import {fetchPartyInfo} from "../../store/parties";

const {Stomp} = require('stompjs/lib/stomp.js');

const createFormValue = (item: JsonWsEventItem): AmmoLogFormProps => ({
    item: {
        ammoAt: normalizeDatetime(item.apiLog?.createdAt),
        partyId: item.eventParty!.partyId,
        eventId: item.eventParty!.eventId,
        apiLog: {
            ...item.apiLog,
        }
    },
    party: {
        ...item.eventParty as JsonPartyInfo,
        partyType: JsonPartyInfoPartyTypeEnum.Fo,
        eventDetails: [{
            eventId: item.eventParty!.eventId,
            ...item.eventParty,
        } as JsonPartyEventDetails]
    },
})

interface RelayState {
    stompClient?: Client,
    isLoading: boolean,
    isConnected: boolean,
    items: { [key: string]: JsonWsEventItem }, // by itemId
    errors: string[]
}

const defaultRelayState: RelayState = {
    stompClient: undefined,
    isLoading: true,
    isConnected: false,
    items: {},
    errors: []
}

interface ApiItemType extends JsonEventPartyInfo, JsonWsEventItem { // JsonApiEventParty
    createdAt: JsonApiLog['createdAt'],
    barcode: JsonApiLog['barcode'],
}

interface ApiItemsGridFilter extends DataGridFilter {
}

interface ApiItemsListGridState extends DataGridState<ApiItemType, ApiItemsGridFilter> {
}

const apiItemsCols: DataGridCol<ApiItemType>[] = [
    createCol('Čas', 'createdAt', 100, undefined, (v) => dateToGuiAs(v, "dd.MM.yyyy HH:mm:ss")),
    createCol('Jméno', 'lastName', 100, undefined, (v, item) =>
        <Link underline={'hover'} to={`/parties/${item.partyId}/${item.eventId}`} component={RouterLink}>{partyName(item)}</Link>),
    {
        title: 'F',
        tooltip: 'Fotografie',
        size: 20,
        align: "center",
        col: 'photoGuid',
        renderValue: (v, item) => {
            return <PartyPhotoTooltip photoGuid={v} icon={item.photoGuid ?
                <div><PartyPhoto photoGuid={item.photoGuid} maxHeight={24} maxWidth={40}/></div> : undefined}/>
        }
    },
    // createCol('Štítky / Firma', 'tags', 85, undefined, (v, item) => {
    //     const items = [];
    //     if (v) {
    //         items.push(<PartyTags key={'tags'} tags={v} partyId={item.partyId} short/>);
    //     }
    //     if (item.companyId) {
    //         items.push(<small style={v ? {
    //             display: 'inline-block',
    //             marginTop: '10px'
    //         } : undefined} key={'company'}><CodebookValue value={item.companyId} name={'company'}/></small>)
    //     }
    //     return items;
    // }),
    createCol('F', 'formatCode', '30C', 'Formát', (_, item) =>
        <EventPartyAccredDetail item={item} col={'formatCode'}/>),
    createCol('Reason', 'reason', 100, undefined, (_, item) =>
        <small><EventPartyAccredDetail item={item} col={'reason'}/></small>),
];

const apiItemsDefaultState: ApiItemsListGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'createdAt',
        orderDir: OrderDirType.DESC
    },
};

interface EventAmmoLogsGridFilter extends DataGridFilter, GetAmmoLogListUsingGETRequest {
}

interface EventAmmoLogsListGridState extends DataGridState<JsonAmmoLogInfo, EventAmmoLogsGridFilter> {
}

const eventAmmoLogCols: DataGridCol<JsonAmmoLogInfo>[] = [
    createCol('Čas', 'ammoAt', 100, undefined, (v) => dateToGuiAs(v, "dd.MM.yyyy HH:mm:ss")),
    createCol('Jméno', 'lastName', 100, undefined, (v, item) =>
        <Link underline={'hover'} to={`/parties/${item.partyId}/${item.eventId}`} component={RouterLink}>{partyName(item)}</Link>),
    {
        title: 'F',
        tooltip: 'Fotografie',
        size: 20,
        align: "center",
        col: 'photoGuid',
        renderValue: (v, item) => {
            return <PartyPhotoTooltip photoGuid={v} icon={item.photoGuid ?
                <div><PartyPhoto photoGuid={item.photoGuid} maxHeight={24} maxWidth={40}/></div> : undefined}/>
        }
    },
    createCol('F', 'formatCode', '30C', 'Formát', (_, item) =>
        <EventPartyAccredDetail item={item as JsonEventPartyInfo} col={'formatCode'}/>),
    createCol('Reason', 'reason', '50C', undefined, (_, item) =>
        <small><EventPartyAccredDetail item={item as JsonEventPartyInfo} col={'reason'}/></small>),
    createCol('Množství', 'ammoAmount', '50R', undefined, (v, item) => {
        return formatDecimal(v);
    }),
    createCol('Směr', 'ammoLogType', 30, undefined, (v, item) => {
        return v === JsonAmmoLogInfoAmmoLogTypeEnum.Incoming
            ? <ArrowDownwardOutlined color={'success'}/>
            : <ArrowUpwardOutlined color={'error'}/>;
    }),
];

const eventAmmoLogsDefaultState: EventAmmoLogsListGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'ammoAt',
        orderDir: OrderDirType.DESC
    },
};

type JsonAmmoLogStatInfo = {
    reason: string,
    incoming: number,
    outgoing: number,
    balance: number
}

interface EventAmmoLogStatsGridFilter extends DataGridFilter {
}

interface EventAmmoLogStatsListGridState extends DataGridState<JsonAmmoLogStatInfo, EventAmmoLogStatsGridFilter> {
}

const eventAmmoLogStatCols: DataGridCol<JsonAmmoLogStatInfo>[] = [
    createCol('Reason', 'reason', 100),
    createCol('Příchozí', 'incoming', '50R', undefined, (v, item) => {
        return formatDecimal(v);
    }),
    createCol('Odchozí', 'outgoing', '50R', undefined, (v, item) => {
        return formatDecimal(v);
    }),
    createCol('Celkem', 'balance', '50R', undefined, (v, item) => {
        return formatDecimal(v);
    }),
];

const eventAmmoLogStatsDefaultState: EventAmmoLogStatsListGridState = {
    filter: {
        ...defaultDataGridFilterState,
    },
};

const EventAmmoLogsList = (props: { event: JsonEventInfo, user: AuthState['user'] }) => {
    const {event, user} = props;
    const {eventId} = event;
    const userId = user?.userId;
    const dispatch = useAppDispatch();
    const t = useAppTranslation();

    const [relayState, setRelayState] = useState(defaultRelayState);
    const [isSynced, setIsSynced] = useState(false);

    const [apiItemsState, setApiItemsState] = useState<ItemsState<ApiItemType, ApiItemsGridFilter>>(createDefaultListState());
    const [isScannerEnabled, setIsScannerEnabled] = useState(false);
    const [showMode, setShowMode] = useState<'all' | 'ignored' | 'pending' | 'done'>('pending');

    const [ammoLogItemsState, setAmmoLogItemsState] = useState<ItemsState<JsonAmmoLogInfo, EventAmmoLogsGridFilter>>(createDefaultListState());
    const [editEventAmmoLog, setEditEventAmmoLog] = useState<AmmoLogFormProps>();

    const [ammoLogStatItemsState, setAmmoLogStatItemsState] = useState<ItemsState<JsonAmmoLogStatInfo, EventAmmoLogStatsGridFilter>>(createDefaultListState());

    const [doneLogIds, setDoneLogIds] = useState<{ [key in number]: number }>({});
    const [ignoredIds, setIgnoredIds] = useState<number[]>([]);

    const handleIncomingMessage = useCallback((payload: any/*Frame | string*/) => {
        // if (!(payload instanceof Stomp.Frame)) {
        //     console.log("Invalid payload: " + payload);
        // }
        setRelayState(state => ({...state, isLoading: false}));
        if (!payload.body) {
            setRelayState(state => ({...state, errors: [payload, ...state.errors.slice(0, 2)]}));
            return;
        }

        const message = JSON.parse(payload.body) as JsonWsMessage;
        const messages = message.type === JsonWsMessageTypeEnum.Bundle ? message.data as JsonWsMessage[] : [message];
        const isSnapshot = messages[0].type === JsonWsMessageTypeEnum.Snapshot;
        if (isSnapshot) {
            setRelayState(state => ({
                ...state,
                items: {},
                errors: []
            }));
            setIsSynced(true);
        }

        messages.forEach(message => {
            switch (message.type) {
                case JsonWsMessageTypeEnum.Snapshot:
                    // no-op
                    break;
                case JsonWsMessageTypeEnum.EventItem:
                    const item = message.data as JsonWsEventItem;
                    setRelayState(state => ({
                        ...state,
                        items: {
                            ...state.items,
                            [item.itemId!]: item
                        },
                    }));
                    if (!!item.apiLog?.ammoLogId && item.apiLog.logId) {
                        setDoneLogIds((current) => {
                            return {...current, [item.apiLog?.logId!]: item.apiLog?.ammoLogId!};
                        })
                    } else if (item.apiLog?.ammoStatus === JsonApiLogAmmoStatusEnum.Ignored) {
                        setIgnoredIds(ids => [...ids.filter(id => id !== item.apiLog!.logId!), item.apiLog!.logId!]);
                    } else if (item.eventParty) {
                        setEditEventAmmoLog(current => {
                            if (!current && item.eventParty) {
                                setEditEventAmmoLog(createFormValue(item));
                            }
                            return current;
                        })
                    }
                    break;

                default:
                case JsonWsMessageTypeEnum.Error:
                    let error = JSON.stringify(message.data); // JsonErrorInfo
                    if ((message?.data as any)['errorCode'] === 'INVALID_PARAMETER') {
                        if ((message?.data as any)['values'] && (message?.data as any)['values']['parameterName'] === 'userId') {
                            error = t('Neplatné ID uživatele - nedošlo k odhlášení?')
                        }
                        if ((message?.data as any)['values'] && (message?.data as any)['values']['parameterName'] === 'eventId') {
                            error = t('Neplatné ID události')
                        }
                    }

                    setRelayState(state => ({
                        ...state,
                        errors: [error, ...state.errors.slice(0, 2)]
                    }));
                    break;
            }
        });
    }, [t]);

    // initial connect & subscribe
    useEffect(() => {
        let stompClient: Client;

        let retryTimeout: any, syncTimeout: any, cleanupInterval: any, cleanupCount: 0;
        const connect = () => {
            setRelayState(state => ({...state, isLoading: true}));

            stompClient = Stomp.over(new SockJS(apiBasePath() + '/ws-service'));
            stompClient.debug = () => {
            };
            stompClient.connect({userId}, () => {
                stompClient.subscribe('/topic/event/' + eventId, handleIncomingMessage, {userId});
                stompClient.subscribe('/user/event/' + eventId, handleIncomingMessage, {userId});

                syncTimeout = setTimeout(() => {
                    if (stompClient?.connected) {
                        setRelayState(state => ({...state, isLoading: true}));
                        stompClient?.send('/app/event/' + eventId + '/sync', {userId});
                    }
                }, 1000);

                setRelayState(state => ({
                    ...state,
                    errors: [],
                    stompClient: stompClient,
                    isConnecting: false,
                    isConnected: true
                }));

            }, () => {
                setRelayState(state => ({...state, isLoading: false, isConnected: false}));
                retryTimeout = setTimeout(connect, 10000);
            });
        }
        retryTimeout = setTimeout(connect, 2000);

        return (() => {
            if (syncTimeout) {
                clearTimeout(syncTimeout);
            }
            if (retryTimeout) {
                clearTimeout(retryTimeout);
            }
            cleanupInterval = setInterval(() => {
                if (stompClient?.connected) {
                    stompClient?.disconnect(console.log);
                    clearInterval(cleanupInterval);
                } else if (cleanupCount++ > 30) {
                    clearInterval(cleanupInterval);
                }
            }, 1000);
        })
    }, [eventId, userId, handleIncomingMessage]);

    // make sure we keep pinging "sync" until we get it
    useEffect(() => {
        if (isSynced || !relayState.stompClient?.connected) {
            return;
        }
        let syncInterval = setInterval(() => {
            if (isSynced) {
                clearInterval(syncInterval);
                return;
            }
            if (relayState.stompClient?.connected) {
                setRelayState(state => ({...state, isLoading: true}));
                relayState.stompClient?.send('/app/event/' + eventId + '/sync', {userId});
            }

        }, 15000);

        return (() => {
            if (syncInterval) {
                clearInterval(syncInterval);
            }
        });

    }, [eventId, userId, isSynced, relayState.stompClient]);


    let errors: JSX.Element[] = [];
    if (relayState.errors) {
        errors = relayState.errors.filter((v, i, a) => a.indexOf(v) === i)
            .map((error, i) =>
                <Alert key={i} severity="error">{error}</Alert>)
        if (errors.length > 0) {
            errors.push(<div key={errors.length}>
                <Button variant={'contained'} color={'error'} fullWidth onClick={() => window.location.reload()}
                >{t('Obnovit stránku')}</Button>
            </div>);
        }
    }

    const handleFetchAmmoLogs = useCallback(() => {
        setAmmoLogItemsState((s) => ({...s, loading: true}));
        setAmmoLogStatItemsState((s) => ({...s, loading: true}));
        if (eventId) {
            dispatch(fetchAmmoLogs({eventId, rows: 10000})).then((res) => {
                const items = (getApiResult<JsonAmmoLogInfo[]>(res) || []).filter(a => a.status === JsonAmmoLogInfoStatusEnum.Active);

                const stats: JsonAmmoLogStatInfo[] = [];
                items.forEach((item) => {
                    const reason = item.reason || 'Ostatní';
                    if (!stats.find(s => s.reason === reason)) {
                        stats.push({
                            reason,
                            incoming: 0,
                            outgoing: 0,
                            balance: 0
                        })
                    }
                });
                stats.push({
                    reason: 'Celkem',
                    incoming: 0,
                    outgoing: 0,
                    balance: 0
                })
                stats.forEach((stat) => {
                    const mine = items.filter((item) => item.reason === stat.reason
                        || stat.reason === 'Celkem'
                        || (!item.reason && stat.reason === 'Ostatní'));
                    stat.incoming = mine.reduce((s, item) => item.ammoLogType !== 'INCOMING' ? s : s + (item.ammoAmount || 0), 0);
                    stat.outgoing = mine.reduce((s, item) => item.ammoLogType !== 'OUTGOING' ? s : s + (item.ammoAmount || 0), 0);
                    stat.balance = stat.incoming - stat.outgoing;
                })

                setAmmoLogItemsState((s) => ({
                    ...s,
                    loading: false,
                    items
                }));
                setAmmoLogStatItemsState((s) => ({
                    ...s,
                    loading: false,
                    items: stats
                }));
            });
        } else {
            setAmmoLogItemsState((s) => ({...s, loading: false, items: []}));
            setAmmoLogStatItemsState((s) => ({...s, loading: false, items: []}));
            setEditEventAmmoLog(undefined);
        }

    }, [eventId, dispatch]);

    const handleEditEventAmmoLog = useCallback((item?: JsonAmmoLogInfo, apiLog?: JsonApiLog) => {
        const ammoLogId = item?.ammoLogId || apiLog?.ammoLogId;
        if (!ammoLogId) {
            return;
        }
        dispatch(fetchAmmoLog(ammoLogId)).then(async (res) => {
            const ammoLog = getApiResult<JsonAmmoLog>(res);
            if (ammoLog) {
                setEditEventAmmoLog({
                    item: {
                        ...ammoLog,
                        ammoAt: normalizeDatetime(ammoLog.ammoAt),
                        apiLog
                    },
                    party: getApiResult(await dispatch(fetchPartyInfo(ammoLog.partyId!)))
                })
            }
        });
    }, [dispatch]);

    const handleEditApiItem = useCallback((item: ApiItemType) => {
        if (item.apiLog?.ammoLogId) {
            handleEditEventAmmoLog(undefined, item.apiLog);
        } else {
            setEditEventAmmoLog(createFormValue(item));
        }
    }, [handleEditEventAmmoLog]);

    const syncItems = useCallback((eventAmmoLog: JsonAmmoLog, isDone: boolean) => {

        setEditEventAmmoLog(undefined);
        const apiLog = eventAmmoLog.apiLog;
        if (!apiLog) {
            handleFetchAmmoLogs();
            return;
        }

        if (isDone) {
            setDoneLogIds((current) => {
                return {...current, [apiLog.logId!]: apiLog.ammoLogId!};
            })
            handleFetchAmmoLogs();
        }
        if (apiLog.ammoStatus === JsonApiLogAmmoStatusEnum.Ignored) {
            setIgnoredIds(ids => [...ids.filter(id => id !== apiLog.logId), apiLog.logId!]);
        } else {
            setIgnoredIds(ids => [...ids.filter(id => id !== apiLog.logId!)]);
        }

    }, [handleFetchAmmoLogs]);

    const handleSaveLog = useCallback(async (eventAmmoLog: JsonAmmoLog, isDelete?: boolean) => {
        let res;
        if (isDelete !== undefined) {
            res = await dispatch(updateApiLog({
                json: {
                    ...eventAmmoLog.apiLog!,
                    ammoStatus: isDelete ? JsonApiLogAmmoStatusEnum.Ignored : undefined
                },
            }));
        } else {
            return;
        }

        const apiLog = getApiResult<JsonApiLog>(res);
        if (apiLog) {
            eventAmmoLog.apiLog = apiLog;

            addApiResultMessage(res, {
                [ApiChangeType.NO_CHANGE]: 'Sken ponechán beze změn',
                [ApiChangeType.UPDATED]: isDelete ? 'Sken úspěšně ignorován' : 'Sken úspěšně obnoven',
                [ApiChangeType.CREATED]: 'Sken úspěšně vytvořen'
            }, t, dispatch);

            syncItems(eventAmmoLog, false);
        }

    }, [syncItems, dispatch, t])

    const handleSaveAmmoLog = useCallback(async (eventAmmoLog: JsonAmmoLog, isDelete?: boolean) => {
        const res = await dispatch(saveAmmoLog({
            ...eventAmmoLog,
            status: (isDelete ? JsonAmmoLogStatusEnum.Deleted : eventAmmoLog.status)
        }));

        if (getApiResult(res)) {
            addApiResultMessage(res, {
                [ApiChangeType.NO_CHANGE]: 'Záznam ponechán beze změn',
                [ApiChangeType.UPDATED]: isDelete ? 'Záznam úspěšně odstraněn' : 'Záznam úspěšně upraven',
                [ApiChangeType.CREATED]: 'Záznam úspěšně vytvořen'
            }, t, dispatch);

            syncItems(eventAmmoLog, true);
        }

    }, [syncItems, dispatch, t]);

    const handleCancelEventAmmoLog = useCallback(() => {
        setEditEventAmmoLog(undefined);
    }, []);

    const logBoxActions: InfoBoxProps['actions'] = useMemo(() => {
        return [{
            title: 'Přidat záznam ručně',
            color: 'primary',
            action: () => setEditEventAmmoLog({
                item: {
                    eventId: eventId,
                    ammoAt: normalizeDatetime(new Date())
                }
            })
        }]
    }, [eventId]);

    const ammoLogActions: DataGridItemAction<JsonAmmoLogInfo>[] = useMemo(() => [
        {
            title: 'Upravit záznam',
            icon: <Edit/>,
            callback: (item) => handleEditEventAmmoLog(item)
        }
    ], [handleEditEventAmmoLog]);

    const apiItemActions: DataGridItemAction<ApiItemType>[] = useMemo(() => [
        {
            title: 'Zpracovat',
            icon: <PostAddOutlined/>,
            isApplicable: (item) => !doneLogIds[item.apiLog?.logId!] && !ignoredIds.includes(item.apiLog?.logId!),
            callback: handleEditApiItem
        },
        {
            title: 'Upravit záznam',
            icon: <DoneRounded/>,
            color: 'success',
            isApplicable: (item) => !!doneLogIds[item.apiLog?.logId!],
            callback: handleEditApiItem
        },
        {
            title: 'Obnovit',
            icon: <RefreshOutlined/>,
            isApplicable: (item) => !doneLogIds[item.apiLog?.logId!] && ignoredIds.includes(item.apiLog?.logId!),
            callback: (item) => handleSaveLog(item as JsonAmmoLog, false)
        },
    ], [handleEditApiItem, handleSaveLog, doneLogIds, ignoredIds]);

    useEffect(() => {
        if (relayState.isLoading) {
            return;
        }

        const items: ApiItemType[] = [];
        Object.keys(relayState.items).forEach((itemId) => {
            const item = relayState.items[itemId];
            const visible = showMode === 'all'
                || (showMode === 'ignored' && !doneLogIds[item.apiLog?.logId!] && ignoredIds.includes(item.apiLog?.logId!))
                || (showMode === 'done' && doneLogIds[item.apiLog?.logId!])
                || (showMode === 'pending' && !doneLogIds[item.apiLog?.logId!] && !ignoredIds.includes(item.apiLog?.logId!));
            if (!visible) {
                return;
            }
            items.push({
                ...item,
                createdAt: item.apiLog?.createdAt,
                barcode: item.apiLog?.barcode,
                ...item.eventParty
            });
        });

        setApiItemsState(s => ({
            ...s,
            loading: false,
            items
        }));

    }, [showMode, ignoredIds, doneLogIds, relayState.isLoading, relayState.items]);

    useEffect(() => {
        handleFetchAmmoLogs();
    }, [handleFetchAmmoLogs]);

    return <>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Grid container spacing={2}>
                    {isScannerEnabled && <Grid item xs={12}>
                        <AmmoScanner compact suspended={!!editEventAmmoLog}/>
                    </Grid>}
                    <Grid item xs={12}>
                        <InfoBox title={'Záznamy čtečky'}>
                            <>
                                <div className={'info-box-actions'}>
                                    <Button variant={isScannerEnabled ? 'contained' : 'outlined'} size={'small'} onClick={() => setIsScannerEnabled(!isScannerEnabled)}>
                                        {isScannerEnabled ? t('Zavřít čtečku') : t('Lokální čtečka')}
                                    </Button>
                                </div>
                                <div className={'data-grid-filter'}>
                                    <ButtonGroup disabled={relayState.isLoading || !relayState.isConnected}>
                                        <Button variant={showMode === 'pending' ? 'contained' : 'outlined'} size={'small'} onClick={() => setShowMode('pending')}>
                                            {t('Čekající')}
                                        </Button>
                                        <Button variant={showMode === 'done' ? 'contained' : 'outlined'} size={'small'} onClick={() => setShowMode('done')}>
                                            {t('Vyřízené')}
                                        </Button>
                                        <Button variant={showMode === 'ignored' ? 'contained' : 'outlined'} size={'small'} onClick={() => setShowMode('ignored')}>
                                            {t('Ignorované')}
                                        </Button>
                                        <Button variant={showMode === 'all' ? 'contained' : 'outlined'} size={'small'} onClick={() => setShowMode('all')}>
                                            {t('Vše')}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                                <div style={{flexBasis: '100%', order: 20, position: 'relative'}}>
                                    {relayState.isLoading
                                        ? <LinearProgress variant={'query'} sx={{position: 'absolute', top: '10px'}}/>
                                        : (relayState.isConnected ? null :
                                            <Alert severity="warning" sx={{margin: '10px 0'}}>{t('Spojení přerušeno, probíhá pokus o obnovu ...')}</Alert>)}
                                    {!!errors && <div>{errors}</div>}
                                </div>
                                {(!relayState.isLoading || !apiItemsState.loading) && <DataGrid
                                    cols={apiItemsCols}
                                    defaultState={apiItemsDefaultState}
                                    itemsState={apiItemsState}
                                    mode={DataGridMode.CLIENT}
                                    actions={apiItemActions}
                                    emptyListMessage={showMode === 'pending' ? t('Žádné záznamy k vyřízení, čeká se na kód ze čtečky ...') : undefined}
                                    tableProps={{maxHeight: "400px"}}
                                />}
                            </>
                        </InfoBox>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InfoBox title={'Aktuální stav střeliva'}>
                            <DataGrid
                                cols={eventAmmoLogStatCols}
                                defaultState={eventAmmoLogStatsDefaultState}
                                itemsState={ammoLogStatItemsState}
                                mode={DataGridMode.CLIENT}
                                getRowClassNames={(item) => item.reason === 'Celkem' ? ['data-grid-total-row'] : undefined}
                            />
                        </InfoBox>
                    </Grid>
                    <Grid item xs={12}>
                        <InfoBox title={'Záznamy střeliva'} actions={logBoxActions}>
                            <DataGrid
                                cols={eventAmmoLogCols}
                                defaultState={eventAmmoLogsDefaultState}
                                itemsState={ammoLogItemsState}
                                mode={DataGridMode.CLIENT}
                                actions={ammoLogActions}
                                tableProps={{maxHeight: "400px"}}
                            />
                        </InfoBox>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {editEventAmmoLog && <EventAmmoLogModal
            {...editEventAmmoLog}
            title={editEventAmmoLog.item?.ammoLogId ? 'Úprava záznamu střeliva' : 'Záznam střeliva'}
            onCancel={handleCancelEventAmmoLog}
            onSave={handleSaveAmmoLog}
            onDelete={editEventAmmoLog.item.ammoLogId
                ? (v) => handleSaveAmmoLog(v, true)
                : (editEventAmmoLog.item.apiLog?.logId ? (v) => handleSaveLog(v, true) : undefined)}
            deleteButtonTitle={editEventAmmoLog.item.ammoLogId ? 'Smazat' : 'Ignorovat'}
            deleteConfirmText={editEventAmmoLog.item.ammoLogId ? 'Skutečně trvale smazat tento záznam?' : 'Skutečně trvale ignorovat tento sken?'}
        />}
    </>;
}

export default EventAmmoLogsList;
