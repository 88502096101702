import * as React from "react";
import {CSSProperties, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {
    ChevronLeft,
    ChevronRight,
    Clear,
    CloudDownload,
    FilterAlt,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
    MoreVertRounded
} from "@mui/icons-material";
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    ClickAwayListener,
    FormControl,
    Grid,
    IconButton,
    LinearProgress,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {TAppFunction, useAppTranslation} from "../services/i18n";
import {Formik, FormikProps} from "formik";
import {useSearchParams} from "react-router-dom";
import {ItemsState, useAppSelector} from "../store";
import {Color, isNumeric} from "../model/form";
import {apiBasePath} from "../app/config";
import {selectAuthInfo} from "../store/selectors";
import {AuthState} from "../store/auth";

export const enum DataGridMode { CLIENT = 'client', SERVER = 'server'}

export const enum OrderDirType { ASC = 'asc', DESC = 'desc' }

const PAGE_SIZES: number[] = [20, 50, 100, 500];
const MASS_ALL_ITEMS_LIMIT = 500;

export type DataGridCol<E, F extends DataGridFilter = {}> = {
    [K in keyof E]: {
        title: string | JSX.Element
        size: number,
        align?: 'left' | 'center' | 'right',
        tooltip?: string,
        col: K,
        orderCol?: K | null,
        renderValue?: (value: E[K], item: E, filter?: F) => React.ReactNode,
        subCols?: DataGridCol<E, F>[]
    }
}[keyof E]

export function createCol<E, K extends keyof E, F extends DataGridFilter = {}>(
    title: string | JSX.Element,
    col: K,
    size: number | string,
    tooltip?: string,
    renderValue?: (value: E[K], item: E, filter?: F) => React.ReactNode,
    subCols?: DataGridCol<E, F>[]
): DataGridCol<E, F> {
    let align = undefined;
    if (typeof size === 'string') {
        align = size.substring(size.length - 1) === 'C' ? 'center' : 'right';
        size = parseInt(size.substring(0, size.length - 1), 10);
    }
    return {
        title,
        size,
        align,
        tooltip,
        col,
        orderCol: col,
        renderValue,
        subCols
    } as DataGridCol<E, F>
}

export interface DataGridItemAction<E, F extends DataGridFilter = {}> {
    title: string;
    icon: JSX.Element;
    color?: Color,
    isApplicable?: (item: E) => boolean,
    callback: (item: E, filter: F) => unknown;
}

export type DataGridMasActionCallback<E, F extends DataGridFilter, A> = (
    action: A,
    filter: F,
    items: E[] | boolean
) => void;

export interface DataGridMasAction<E, F extends DataGridFilter, A extends string> {
    action: A;
    title: string;
    isApplicable?: (item: E) => boolean,
    callback: DataGridMasActionCallback<E, F, A>,
    icon?: JSX.Element
}

export interface DataGridTableProps {
    maxHeight?: string
    minHeight?: string
}

export interface DataGridFilter {
    search?: string,
    start?: number,
    rows?: number,
    orderCol?: string,
    orderDir?: string,
}

export interface DataGridState<E, F extends DataGridFilter> {
    filter: F,
    filterCallback?: (filter: F, item: E) => boolean,
}

export const defaultDataGridFilterState = {
    orderDir: OrderDirType.ASC,
    start: 0,
    rows: parseInt(window?.localStorage?.getItem("PAGE_SIZE") || "", 10) || PAGE_SIZES[0]
}

export type DataGridProps<E, F extends DataGridFilter, A extends string = ''> = {
    header?: (filter: F) => JSX.Element,
    cols: Array<DataGridCol<E, F>>,
    itemsState: ItemsState<E, F>,
    defaultState: DataGridState<E, F>,
    handleFetchItems?: (filter: F) => void,
    filterFields?: (props: DataGridFilterProps<F>, t: TAppFunction) => JSX.Element,
    emptyListMessage?: string,
    filter?: any,
    actions?: DataGridItemAction<E>[],
    mode?: DataGridMode,
    itemKey?: keyof E,
    massActions?: DataGridMasAction<E, F, A>[],
    tableProps?: DataGridTableProps,
    getRowClassNames?: (item: E, filter?: F) => string[] | undefined | { classNames?: string[], style?: CSSProperties },
    exportPath?: string,
    className?: string,
    onFilterChange?: (filter: F) => void
}

export type DataGridFilterProps<F extends DataGridFilter> = {
    filter: F,
    formProps: FormikProps<F>,
    buttons: JSX.Element,
    user: AuthState['user'],
    rights: AuthState['rights']
}

type DataGridMassActionState<E, A extends string = ''> = {
    selectedItems: E[],
    isAllItems: boolean,
    selectedAction?: A
}

export const localCompare = <T, >(va: T, vb: T): number => {
    if (va === undefined || va === null) {
        if (vb === undefined || vb === null) {
            return 0;
        }
        return -1;
    }
    if (vb === undefined || vb === null) {
        return 1;
    }
    if ((va as any)?.localeCompare) {
        return (va as any).localeCompare(vb);
    }
    if (va > vb) {
        return 1;
    } else if (vb < va) {
        return -1;
    }
    return 0;
}

const localSort = <E, F extends DataGridFilter>(state: DataGridState<E, F>, items: Array<E>) => {
    const {filter} = state;
    return items.slice().filter((item) => {
        if (filter && state.filterCallback) {
            return state.filterCallback(filter, item);
        }
        return true;

    }).sort((a: E, b: E) => {
        if (a === b || !filter.orderCol) {
            return 0;
        }
        let r: number = 0;
        filter.orderCol.split(',').forEach((orderCol) => {
            if (r !== 0) {
                return;
            }
            const va = a[orderCol as keyof E];
            const vb = b[orderCol as keyof E];
            r = localCompare(va, vb);
        });
        if (r > 0) {
            return filter.orderDir === OrderDirType.DESC ? -1 : 1;
        } else {
            return filter.orderDir === OrderDirType.DESC ? 1 : -1;
        }
    });
}

const syncFilterFromUrl = <F extends DataGridFilter>(s: URLSearchParams, initStateFilter: F): F => {
    const urlFilter = {
        ...initStateFilter
    }

    let k: keyof F;
    for (k in urlFilter) {
        const q = s.has(k) ? s.getAll(k).filter(v => v !== '') : null;
        const t = typeof urlFilter[k];
        if (q === null) {
            continue;
        }
        if (t === 'number') {
            urlFilter[k] = parseInt(q[0], 10) as any;
            continue;
        }
        if (k.substring(0, 2) === 'is') {
            urlFilter[k] = (q[0] === 'true') as any;
            continue;
        }
        if (t === 'object') {
            if (k.substring(k.length - 3) === 'Ids') {
                urlFilter[k] = q.map((v) => parseInt(v, 10)) as any;
            } else {
                urlFilter[k] = q as any;
            }
            continue;
        }
        const v = q[0];
        if (k !== 'search' && isNumeric(v)) {
            urlFilter[k] = parseInt(v, 10) as any;
            continue;
        }
        urlFilter[k] = q[0] as any;
    }
    return urlFilter;
}

const syncUrlFromFilter = <F extends DataGridFilter>(filter: F, s: URLSearchParams, initStateFilter: F, keepInitFilter?: boolean): boolean => {
    let k: keyof typeof initStateFilter;
    let changed = false;
    for (k in initStateFilter) {
        const name = k as any;
        if (filter[k] !== undefined) {
            if (!keepInitFilter && JSON.stringify(filter[k]) === JSON.stringify(initStateFilter[k])) {
                s.delete(name);

            } else if (filter[k] instanceof Array) {
                const current = s.has(name) ? s.getAll(name).filter(v => v !== '').map(v => isNumeric(v) ? parseInt(v, 10) : v) : undefined;
                if (JSON.stringify(current) === JSON.stringify(filter[k])) {
                    continue;
                }
                s.delete(name);
                if ((filter[k] as any[]).length <= 0) {
                    s.set(name, ''); // empty string to trigger URL change
                } else {
                    (filter[k] as any[]).forEach((v) => {
                        s.append(name, v);
                    })
                }
                changed = true;
            } else {
                const current = s.has(name) ? s.get(name) : undefined;
                if ((current || '').toString() !== (filter[k] === false ? 'false' : (filter[k] === 0 ? '0' : (filter[k] || ''))).toString()) {
                    s.set(name, filter[k] as any);
                    changed = true;
                }
            }

        } else if (s.get(name)) {
            s.delete(name);
            changed = true;
        }
    }
    return changed;
}

const DataGridItemActions = <E, F extends DataGridFilter>(props: {
    actions?: DataGridItemAction<E, F>[],
    item: E,
    filter: F
}) => {
    const t = useAppTranslation();

    const {actions, item, filter} = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const applicableActions: DataGridItemAction<E, F>[] = useMemo(() => {
        return actions?.filter((action) => !action.isApplicable || action.isApplicable(item)) || []
    }, [item, actions]);

    const buttons: JSX.Element[] | undefined = useMemo(() => {
        return applicableActions.map((action, i) => {
            return <MenuItem key={i} color={action.color || 'secondary'} disableGutters
                onClick={() => {
                    action.callback(item, filter);
                    handleClose();
                }}>
                <ListItemIcon>{action.icon}</ListItemIcon>
                <ListItemText>{t(action.title)}</ListItemText>
            </MenuItem>;
        })
    }, [applicableActions, item, filter, t]);

    if (!actions || !buttons?.length || !(buttons.length > 0)) {
        return null;
    }

    return <TableCell className={'data-grid-actions'}>
        <div>
            <Button
                onClick={applicableActions.length === 1 ? () => applicableActions[0].callback(item, filter) : handleClick}
                variant={open ? 'contained' : 'text'}
                color={applicableActions.length === 1 ? applicableActions[0].color : undefined}
            >
                {applicableActions.length === 1 ? applicableActions[0].icon : <MoreVertRounded/>}
            </Button>
            {applicableActions.length > 1 && <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={handleClose}>
                    <Box className={'context-menu'}>
                        <MenuList autoFocusItem={false}>
                            {buttons}
                        </MenuList>
                    </Box>
                </ClickAwayListener>
            </Popper>}
        </div>
    </TableCell>;
}

const normalizeFilter = <F, >(filter: F): F => {
    if (!filter) {
        return filter;
    }
    const f = {} as F;
    Object.keys(filter).forEach((k) => {
        if (!filter.hasOwnProperty(k)) {
            return;
        }
        const v = (filter as any)[k];
        if (v === undefined || v === '' || JSON.stringify(v) === '{}' || JSON.stringify(v) === '[]') {
            return;
        }
        (f as any)[k] = v;
    });

    return f;
}

const getSelectedMassAction = <A extends string = ''>(
    selectedMassAction: A | undefined,
    massActions: DataGridMasAction<any, any, A>[]
): DataGridMasAction<any, any, A> | undefined => {
    return massActions.find((ma) => ma.action === selectedMassAction);
}

interface DataGridPaginationProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function DataGridPagination(props: DataGridPaginationProps) {
    const {count, page, rowsPerPage, onPageChange} = props;

    const maxPage = Math.ceil(count / rowsPerPage) - 1;
    return (
        <Box sx={{flexShrink: 0, ml: 2.5}}>
            <IconButton
                onClick={(e) => onPageChange(e, 0)}
                disabled={page === 0}
            >
                <KeyboardDoubleArrowLeft/>
            </IconButton>
            <IconButton
                onClick={(e) => onPageChange(e, page - 1)}
                disabled={page === 0}
            >
                <ChevronLeft/>
            </IconButton>
            <IconButton
                onClick={(e) => onPageChange(e, page + 1)}
                disabled={page >= maxPage}
            >
                <ChevronRight/>
            </IconButton>
            <IconButton
                onClick={(e) => onPageChange(e, maxPage)}
                disabled={page >= maxPage}
            >
                <KeyboardDoubleArrowRight/>
            </IconButton>
        </Box>
    );
}

type DataGridCellProps<E, F extends DataGridFilter> = {
    item: E,
    col: DataGridCol<E, F>,
    itemsStateFilter: F | undefined
}

const DataGridCell = <E, F extends DataGridFilter>(props: DataGridCellProps<E, F>) => {
    const {item, col, itemsStateFilter} = props;

    const vv = item[(col.col) as keyof E] as any;
    let v;
    if (col.renderValue) {
        v = col.renderValue(vv, item, itemsStateFilter);
    } else {
        v = vv;
    }
    return <TableCell style={{textAlign: col.align ? col.align : undefined}}><span>{v}</span></TableCell>;
};

type DataGridRowProps<E, F extends DataGridFilter, A extends string = ''> = {
    item: E,
    getRowClassNames?: (item: E, filter?: F, i?: number) => string[] | undefined | {
        classNames?: string[],
        style?: CSSProperties
    },
    hasMassActions: boolean,
    selectedItems: E[],
    cols: Array<DataGridCol<E, F>>,
    itemsStateFilter?: F,
    filter: F,
    itemKey?: keyof E,
    setMassActionState: Dispatch<SetStateAction<DataGridMassActionState<E, A>>>,
    actions?: DataGridItemAction<E, F>[],
    i: number
}

const DataGridRow = <E, F extends DataGridFilter, A extends string = ''>(props: DataGridRowProps<E, F, A>) => {
    const {
        item,
        itemKey,
        hasMassActions,
        selectedItems,
        filter,
        cols,
        itemsStateFilter,
        setMassActionState,
        actions,
        getRowClassNames,
        i
    } = props;

    return useMemo(() => {
        let classNames = [];
        let style: CSSProperties | undefined = undefined;
        if (getRowClassNames) {
            const rc = getRowClassNames(item, itemsStateFilter, i);
            if ((rc as any)?.classNames) {
                classNames = (rc as any)?.classNames;
            } else if (rc) {
                classNames = rc as string[];
            }
            if ((rc as any)?.style) {
                style = (rc as any).style;
            }
        }
        const isRowSelected = hasMassActions && !!itemKey && !!selectedItems.find((s) => s[itemKey] === item[itemKey]);
        if (isRowSelected) {
            classNames.push('data-grid-selected-row');
        }

        return <TableRow className={classNames.join(' ')} style={style}>
            {hasMassActions && <TableCell className={'table-select-box'}>
                <Checkbox size={'small'} checked={isRowSelected} onClick={() => {
                    if (isRowSelected) {
                        setMassActionState((s) => ({
                            ...s,
                            selectedItems: s.selectedItems.filter((s) => s[itemKey] !== item[itemKey])
                        }));
                    } else {
                        setMassActionState((s) => ({...s, selectedItems: [...s.selectedItems, item]}));
                    }
                }}/>
            </TableCell>}
            {cols.map((col, j) => {
                if (col.subCols) {
                    return col.subCols.map((col, k) =>
                        <DataGridCell key={k} item={item} col={col} itemsStateFilter={itemsStateFilter}/>);
                }
                return <DataGridCell key={j} item={item} col={col} itemsStateFilter={itemsStateFilter}/>
            })}
            <DataGridItemActions actions={actions} item={item} filter={filter}/>
        </TableRow>;
    }, [item, itemKey, hasMassActions, filter, cols, itemsStateFilter, selectedItems, setMassActionState, actions, getRowClassNames, i]);
}

// https://mui.com/material-ui/react-table/
export const DataGrid = <E, F extends DataGridFilter, A extends string = ''>(props: DataGridProps<E, F, A>) => {
    const {
        cols,
        defaultState,
        handleFetchItems,
        itemsState,
        filterFields,
        actions,
        massActions,
        tableProps,
        itemKey,
        className
    } = props;

    const t = useAppTranslation();
    const {user, rights} = useAppSelector<AuthState>(selectAuthInfo);
    const [searchParams, setSearchParams] = useSearchParams();
    const {items, count, loading} = itemsState;
    const [state, setState] = useState(defaultState);
    const [massActionState, setMassActionState] = useState<DataGridMassActionState<E, A>>({
        selectedItems: [],
        isAllItems: false
    });
    const {selectedItems} = massActionState;
    const [isFilterLoaded, setIsFilterLoaded] = useState(false); // prevent empty fetch before filter is synced from url
    const {filter} = state;
    const {mode, exportPath, header, onFilterChange} = props;
    const defaultFilter = defaultState.filter;

    useEffect(() => {
        if (mode === DataGridMode.CLIENT) {
            return;
        }
        const urlFilter = syncFilterFromUrl(searchParams, defaultFilter);
        setState((state) => ({...state, filter: urlFilter}));
        setIsFilterLoaded(true);
        if (onFilterChange) {
            onFilterChange(urlFilter);
        }
    }, [defaultFilter, searchParams, onFilterChange, mode]);

    useEffect(() => {
        if (!isFilterLoaded || !handleFetchItems) {
            return;
        }
        handleFetchItems(normalizeFilter(filter));
    }, [handleFetchItems, filter, isFilterLoaded]);

    useEffect(() => {
        setMassActionState((state) => {
            if (!state.isAllItems && state.selectedItems.length <= 0) {
                return state;
            }
            return {
                ...state,
                selectedItems: state.selectedItems?.filter((s) => items.find((item) => itemKey && s[itemKey] === item[itemKey])),
                isAllItems: state.isAllItems && !!items.length && items.length < MASS_ALL_ITEMS_LIMIT
            };
        });

    }, [items, itemKey]);

    const handleFilter = useCallback((filter: F, forceSubmit?: boolean) => {
        let changed = false;
        // setMassActionState((state) => ({...state, selectedItems: [], isAllItems: false}));
        if (mode === DataGridMode.CLIENT) {
            setState((state) => ({...state, filter}));
            if (onFilterChange) {
                onFilterChange(filter);
            }
        } else {
            setSearchParams((s) => {
                changed = syncUrlFromFilter(filter, s, defaultFilter);
                return s;
            });
        }
        if (!changed && forceSubmit && handleFetchItems) {
            handleFetchItems(normalizeFilter(filter));
        }
    }, [mode, defaultFilter, handleFetchItems, onFilterChange, setSearchParams]);

    const handleMassAction = useCallback((massActionState: DataGridMassActionState<E, A>) => {
        if (!massActions || !massActionState.selectedAction) {
            return;
        }
        const ma = getSelectedMassAction(massActionState.selectedAction, massActions);
        if (ma) {
            ma.callback(
                ma.action,
                filter,
                massActionState.isAllItems ? true : massActionState.selectedItems
            );
        }
    }, [filter, massActions]);

    let content;
    if (!items?.length && loading) {
        content = null; // <div className={'data-grid-loading-data'}><LinearProgress/></div>;
    } else {
        let rows: E[];
        if (mode === DataGridMode.CLIENT) {
            rows = localSort(state, items);
        } else {
            rows = items;
        }
        const totalSize = cols.map((col) => col.size).reduce((a, b) => a + b, 0);
        const hasMassActions = !!(massActions && massActions.length);
        let totalColCount = cols.length
            + (hasMassActions ? 1 : 0)
            + (actions && actions.length ? 1 : 0);

        const isHeaderMultiRows = cols.filter((c) => c.subCols && c.subCols.length > 0).length > 0;
        const renderHeaderCol = (col: DataGridCol<E, F>, i: number, totalSize: number) => {
            const w = col.size / totalSize * 100;
            const className = col.orderCol ? 'data-grid-head-sortable' : undefined;
            const onClick = col.orderCol
                ? () => {
                    if (col.orderCol === filter.orderCol) {
                        handleFilter({
                            ...filter,
                            orderDir: filter.orderDir === OrderDirType.ASC ? OrderDirType.DESC : OrderDirType.ASC
                        })
                    } else {
                        handleFilter({...filter, orderDir: OrderDirType.DESC, orderCol: col.orderCol})
                    }
                }
                : undefined;
            if (isHeaderMultiRows && col.subCols?.length) {
                totalColCount = totalColCount + (col.subCols?.length - 1);
            }
            return <TableCell key={i} className={className} onClick={onClick}
                rowSpan={isHeaderMultiRows && !col.subCols ? 2 : undefined}
                colSpan={isHeaderMultiRows && col.subCols ? col.subCols.length : undefined}
                style={{width: w + '%', textAlign: col.align ? col.align : undefined}}>
                    <span title={col.tooltip}>
                        {typeof col.title === 'string' ? t(col.title as string) : col.title}
                        {filter.orderCol && col.orderCol && filter.orderCol.split(/, ?/).indexOf(col.orderCol as string) >= 0
                            ? (filter.orderDir === OrderDirType.ASC
                                ? '↑'
                                : '↓')
                            : null}
                    </span>
            </TableCell>
        };

        content = <TableContainer component={Paper} style={{
            minHeight: tableProps?.minHeight,
            maxHeight: tableProps?.maxHeight
        }}
            className={'data-grid-wrapper' + (className ? ' ' + className : '')}>
            <Table size={'small'} className={'data-grid data-grid-sticky-header'}>
                <TableHead>
                    <TableRow>
                        {hasMassActions &&
                            <TableCell className={'table-select-box'} rowSpan={isHeaderMultiRows ? 2 : undefined}>
                                <Checkbox size={'small'} disabled={!(rows?.length > 0)}
                                    indeterminate={selectedItems.length > 0
                                        && rows?.length > 0
                                        && selectedItems.length !== rows.length}
                                    checked={selectedItems.length > 0
                                        && rows?.length > 0
                                        && selectedItems.length === rows.length}
                                    onClick={() => {
                                        if (selectedItems.length > 0) {
                                            setMassActionState({...massActionState, selectedItems: []});
                                        } else {
                                            setMassActionState({...massActionState, selectedItems: rows});
                                        }
                                    }}
                                />
                            </TableCell>}
                        {cols.map((col, j) => renderHeaderCol(col, j, totalSize))}
                        {actions && actions.length > 0 &&
                            <TableCell className={'table-select-box'} rowSpan={isHeaderMultiRows ? 2 : undefined}></TableCell>}
                    </TableRow>
                    {isHeaderMultiRows && <TableRow>
                        {cols.filter((c) => c.subCols && c.subCols.length > 0).map((parent, i) => {
                            const subCols = parent.subCols as DataGridCol<E, F>[];
                            const totalSize = subCols.map((col) => col.size).reduce((a, b) => a + b, 0);
                            return subCols.map((col, j) => renderHeaderCol(col, j, totalSize));
                        })}
                    </TableRow>}
                </TableHead>
                <TableBody>{rows?.length > 0
                    ? rows.map((item, i) => {
                        const key = itemKey && item[itemKey] ? item[itemKey] as string : 'row-' + i;
                        return <DataGridRow key={key} item={item} itemKey={itemKey}
                            actions={actions}
                            hasMassActions={hasMassActions}
                            selectedItems={selectedItems}
                            cols={cols}
                            itemsStateFilter={itemsState.filter}
                            filter={filter}
                            setMassActionState={setMassActionState}
                            getRowClassNames={props.getRowClassNames}
                            i={i}
                        />
                    })
                    : <TableRow>
                        <TableCell colSpan={totalColCount} className={'data-grid-empty-message'}>
                            <div>{t(props.emptyListMessage || 'Nenalezen žádný záznam')}</div>
                        </TableCell>
                    </TableRow>}
                </TableBody>
                {rows?.length > 0 && count > 0 && <TableFooter>
                    <TableRow>
                        <TableCell colSpan={totalColCount}>
                            <Grid container>
                                {hasMassActions && <Grid item sm={6} className={'data-grid-mass-actions'}>
                                    <FormControl>
                                        <ButtonGroup size={'small'} title={undefined}>
                                            <Button
                                                color={!massActionState.isAllItems ? 'info' : 'inherit'}
                                                variant={!massActionState.isAllItems ? 'contained' : undefined}
                                                disabled={!(selectedItems.length > 0)}
                                                onClick={() => {
                                                    setMassActionState({...massActionState, isAllItems: false});
                                                }}>{t('Vybrané ({{selected}})', {selected: selectedItems.length})}</Button>
                                            {count <= MASS_ALL_ITEMS_LIMIT && <Button
                                                color={massActionState.isAllItems ? 'info' : 'inherit'}
                                                variant={massActionState.isAllItems ? 'contained' : undefined}
                                                onClick={() => {
                                                    setMassActionState({...massActionState, isAllItems: true});
                                                }}>{t('Všechny ({{count}})', {count})}</Button>}
                                        </ButtonGroup>
                                    </FormControl>
                                    <FormControl size="small">
                                        <Select
                                            displayEmpty={true}
                                            renderValue={(v) =>
                                                getSelectedMassAction(v, massActions)?.title
                                                ||
                                                <span style={{color: 'var(--dark-gray)'}}>{t('Hromadná akce')}</span>}
                                            value={massActionState.selectedAction || ''}
                                            onChange={(e, v) => {
                                                const selectedAction = e.target.value as A;
                                                setMassActionState({...massActionState, selectedAction});
                                                if (selectedAction && (massActionState.selectedItems.length > 0 || massActionState.isAllItems)) {
                                                    handleMassAction({...massActionState, selectedAction});
                                                }
                                            }}>
                                            {massActions.map((ma, i) => {
                                                return <MenuItem key={i} value={ma.action}>{ma.icon ?
                                                    <small>{ma.icon}&nbsp;&nbsp;</small> : null}{ma.title}</MenuItem>;
                                            })}
                                        </Select>
                                    </FormControl>
                                    {massActionState.selectedAction
                                        &&
                                        <Button variant={'contained'} color={'primary'} size={'small'} type={'submit'}
                                            onClick={() => handleMassAction(massActionState)}
                                            disabled={!(massActionState.selectedItems.length > 0 || massActionState.isAllItems)}
                                            title={t('Zahájit akci')}><KeyboardDoubleArrowRight/></Button>}
                                </Grid>}
                                <Grid item sm={hasMassActions ? 6 : 12}>
                                    <TablePagination
                                        component={'div'}
                                        rowsPerPageOptions={PAGE_SIZES}
                                        count={count}
                                        rowsPerPage={filter.rows || PAGE_SIZES[0]}
                                        page={Math.floor((filter.start || 0) / (filter.rows || PAGE_SIZES[0]))}
                                        onPageChange={(e, page) => {
                                            handleFilter({...filter, start: page * (filter.rows || PAGE_SIZES[0])})
                                        }}
                                        onRowsPerPageChange={(e) => {
                                            const rows = parseInt(e.target.value, 10);
                                            if (window?.localStorage?.setItem) {
                                                window.localStorage.setItem("PAGE_SIZE", "" + rows);
                                                window.location.replace(window.location.href.replaceAll(/start=[0-9]+/g, ''))
                                                return;
                                            }
                                            handleFilter({...filter, start: 0, rows})
                                        }}
                                        ActionsComponent={DataGridPagination}
                                        labelDisplayedRows={(p) => t("{{from}}–{{to}} / {{total}}", {total: p.count || '?', ...p})}
                                        labelRowsPerPage={null}
                                    />
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableFooter>}
            </Table>
        </TableContainer>;
    }

    const headerEl = useMemo(() => {
        return header ? header(filter) : null;
    }, [header, filter]);

    return <>
        {headerEl}
        {filterFields && user && <div className={'data-grid-filter'}>
            <Formik initialValues={state.filter} onSubmit={(v) => handleFilter({
                ...v,
                start: 0
            })} enableReinitialize>
                {props => {
                    let buttons: JSX.Element[] = [];
                    if (JSON.stringify(filter) !== JSON.stringify(defaultFilter)) {
                        buttons.push(<Button key={'reset'} variant={'text'}
                            onClick={() => {
                                handleFilter({...defaultFilter})
                            }} color={undefined} title={t('Vymazat filtr')}><Clear/></Button>);
                    }

                    if (exportPath) {
                        buttons.push(<Button key={'export'} variant={'text'}
                            onClick={() => {
                                const qs = new URLSearchParams();
                                syncUrlFromFilter({
                                    ...filter,
                                    rows: undefined,
                                    start: undefined
                                }, qs, defaultFilter, true);
                                window.open(apiBasePath() + '/rest-service/' + exportPath + '?' + qs, '_blank');
                            }} color={'success'} title={t('Exportovat dle aktuálního filtru')}><CloudDownload/></Button>);
                    }

                    buttons.push(<Button key={'filter'} variant={'text'} type={'submit'} onClick={() => {
                        handleFilter({...props.values}, true)
                    }} color={undefined} title={t('Použít filtr')}><FilterAlt/></Button>);

                    return <form onSubmit={props.handleSubmit}>
                        {filterFields({
                            filter: state.filter,
                            formProps: props,
                            buttons: <Box className={'data-grid-filter-buttons'}>
                                <ButtonGroup size={'small'}>
                                    {buttons}
                                </ButtonGroup>
                            </Box>,
                            user,
                            rights
                        }, t)}
                    </form>
                }}
            </Formik>
        </div>}
        {(mode !== DataGridMode.CLIENT || loading) && <LinearProgress hidden={!loading}/>}
        {content}
    </>;
}
