/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonGroupSettings
 */
export interface JsonGroupSettings {
    /**
     * 
     * @type {string}
     * @memberof JsonGroupSettings
     */
    areasDefault?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonGroupSettings
     */
    formatCodeDefault?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof JsonGroupSettings
     */
    formatCodeSearch?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonGroupSettings
     */
    groupPositions?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof JsonGroupSettings
     */
    managedGroupIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof JsonGroupSettings
     */
    reasonDefault?: string;
}

export function JsonGroupSettingsFromJSON(json: any): JsonGroupSettings {
    return JsonGroupSettingsFromJSONTyped(json, false);
}

export function JsonGroupSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonGroupSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'areasDefault': !exists(json, 'areasDefault') ? undefined : json['areasDefault'],
        'formatCodeDefault': !exists(json, 'formatCodeDefault') ? undefined : json['formatCodeDefault'],
        'formatCodeSearch': !exists(json, 'formatCodeSearch') ? undefined : json['formatCodeSearch'],
        'groupPositions': !exists(json, 'groupPositions') ? undefined : json['groupPositions'],
        'managedGroupIds': !exists(json, 'managedGroupIds') ? undefined : json['managedGroupIds'],
        'reasonDefault': !exists(json, 'reasonDefault') ? undefined : json['reasonDefault'],
    };
}

export function JsonGroupSettingsToJSON(value?: JsonGroupSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'areasDefault': value.areasDefault,
        'formatCodeDefault': value.formatCodeDefault,
        'formatCodeSearch': value.formatCodeSearch,
        'groupPositions': value.groupPositions,
        'managedGroupIds': value.managedGroupIds,
        'reasonDefault': value.reasonDefault,
    };
}

