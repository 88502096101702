import {Alert, Box, Button, Grid, Tooltip, Typography} from '@mui/material';
import {FormikErrors, FormikProps, useFormikContext} from 'formik';
import {
    createOption,
    createOptions,
    FAKE_VALUE_CUSTOM,
    FormProps,
    getOption,
    OptionValue,
    setNestedKey
} from "../../model/form";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {SelectFormField} from "../form/SelectFormField";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {PartyMassActionProps, PartyMassActionValues, partyName} from "../../model/party";
import {
    createCol,
    DataGrid,
    DataGridCol,
    DataGridFilter,
    DataGridMode,
    DataGridState,
    defaultDataGridFilterState
} from "../DataGrid";
import {
    GetNotificationTemplatesUsingGETNotificationTypeEnum,
    JsonEmailMessage,
    JsonEventPartyInfo,
    JsonEventPartyMassActionRequest,
    JsonEventPartyMassActionRequestActionEnum,
    JsonFile,
    JsonPartyEventDetailsInviteStatusEnum,
    JsonPartyEventDetailsStatusEnum,
    JsonPartyEventDetailsUpdateInviteStatusEnum,
    JsonPartyInfo,
    JsonTemplate,
    PreviewNotificationUsingPOSTNotificationTypeEnum
} from "../../generated-api";
import CodebookValue from "../CodebookValue";
import {dateToGuiAs} from "../../helpers/date";
import {ItemsState, useAppDispatch, useAppSelector} from "../../store";
import {ButtonGroupField, ButtonGroupPlain} from "../form/ButtonGroupField";
import {TextFormField, TextFormFieldPlain} from "../form/TextFormField";
import {AlertColor} from "@mui/material/Alert/Alert";
import {
    inviteExtraLimitOptions,
    inviteIsParkOptions,
    inviteStatusOptions,
    updateInviteStatusOptions
} from "../../model/invite";
import PartyTags from "../PartyTags";
import {EventDaysField} from "../EventDaysField";
import {ModalProps, useModal} from "../../services/modal";
import {AttachFileRounded, DeleteForeverRounded, SendRounded} from "@mui/icons-material";
import {fetchNotificationTemplates, previewNotification} from "../../store/notifications";
import {getApiResult} from "../../helpers/api";
import {EmailPreview} from "../EmailPreview";
import {CodebookState, FIXED_INVITE_TAG_ID, FIXED_VIP_GROUP_ID} from "../../store/codebooks";
import {selectCodebooks} from "../../store/selectors";
import {FormikContextType, FormikState} from "formik/dist/types";
import {renderFile} from "../../helpers/files";
import FileModal from "../../pages/FileModal";
import {CheckboxField} from "../form/CheckboxField";

interface Props extends PartyMassActionProps<PartyMassActionValues<any>, any> {

}

interface LocalFilter extends DataGridFilter {
    action?: JsonEventPartyMassActionRequestActionEnum;
    eventId?: number;
}

interface PartiesGridState extends DataGridState<JsonPartyInfo, LocalFilter> {
}

type ShowMode = 'valid' | 'invalid' | 'all';

const showModeOptions: OptionValue[] = [
    createOption('valid', 'Ukázat jen ty v pořádku', 'Zobrazit jen osoby v pořádku', undefined, 'warning'),
    createOption('invalid', 'Chyby', 'Zobrazit jen osoby s chybou', undefined, 'warning'),
    createOption('all', 'Vše', 'Zobrazit vše', undefined, 'warning'),
];

const addPartyInfo = (key: any, title: JSX.Element | string | undefined, desc: string, severity: AlertColor) => {
    return <Tooltip key={key} title={desc}><Typography color={severity}>{title}</Typography></Tooltip>
}

const PartyDetails = ({item, filter}: { item: JsonPartyInfo, filter: LocalFilter }) => {
    const t = useAppTranslation();

    const {eventId, action} = filter;
    const eventDetails = item.eventDetails;

    const rows: JSX.Element[] = [];

    if (!item.email && action !== JsonEventPartyMassActionRequestActionEnum.Register) {
        rows.push(addPartyInfo('email', t('Chybí email'), t('Není kam zaslat pozvánku'), 'error'));
    }

    if (eventId && eventDetails && eventDetails.length > 0) {
        eventDetails.forEach((ed, i) => {
            if (!ed.eventId || eventId !== ed.eventId) {
                return;
            }

            const rowStatusType: RowStatusType = getRowStatus(item, action, eventId);
            switch (rowStatusType) {
                case 'alreadyThere':
                    rows.push(addPartyInfo(i + '-alreadyThere',
                        <>{t('Již v události')}{!!ed.groupId ? <> (<CodebookValue value={ed.groupId} name={'group'}/>)</> : null}</>,
                        t('Osoba je již v události zaregistrovaná a aktivní'), 'error'));
                    break;
                case 'pendingInvite':
                    rows.push(addPartyInfo(i + '-pendingInvite',
                        <>{t('Aktivní pozvánka')} ({getOption(ed.inviteStatus, inviteStatusOptions)?.label})</>,
                        t('Osoba má již rozpracovanou pozvánku'), 'error'));
                    break;
                case 'pendingUpdateInvite':
                    rows.push(addPartyInfo(i + '-pendingUpdateInvite',
                        <>{t('Aktivní výzva')} ({getOption(ed.updateInviteStatus, updateInviteStatusOptions)?.label})</>,
                        t('Osoba má již rozpracovanou výzvu'), 'error'));
                    break;
                case 'valid':
                case 'missingEmail': // alredy notified
                default:
                    break;
            }
            if (rowStatusType !== 'alreadyThere' && ed.status) {
                if (ed.status === JsonPartyEventDetailsStatusEnum.Deleted) {
                    rows.push(addPartyInfo(i + '-alreadyThere',
                        <>{t('Opakovaná registrace')}{!!ed.groupId ? <> (<CodebookValue value={ed.groupId} name={'group'}/>)</> : null}</>,
                        t('Osoba už v události byla registrovaná, ale následně z ní byla zase odstraněna'), 'info'));
                } else if (ed.status === JsonPartyEventDetailsStatusEnum.Pending) {
                    rows.push(addPartyInfo(i + '-alreadyThere',
                        <>{t('Probíhá registrace')}{!!ed.groupId ? <> (<CodebookValue value={ed.groupId} name={'group'}/>)</> : null}</>,
                        t('Osoba je již v události zaregistrovaná a čeká se na potvrzení'), 'info'));
                } else {
                    rows.push(addPartyInfo(i + '-alreadyThere',
                        <>{t('Již v události')}{!!ed.groupId ? <> (<CodebookValue value={ed.groupId} name={'group'}/>)</> : null}</>,
                        t('Osoba je již v události zaregistrovaná a aktivní'), 'info'));
                }
            }
            if (rowStatusType !== 'pendingInvite' && ed.inviteStatus) {
                if (ed.inviteStatus === JsonPartyEventDetailsInviteStatusEnum.Pending
                    || ed.inviteStatus === JsonPartyEventDetailsInviteStatusEnum.Returned
                    || ed.inviteStatus === JsonPartyEventDetailsInviteStatusEnum.Accepted) {
                    rows.push(addPartyInfo(i + '-pendingInvite',
                        <>{t('Aktivní pozvánka')} ({getOption(ed.inviteStatus, inviteStatusOptions)?.label})</>,
                        t('Osoba má již rozpracovanou pozvánku'), 'info'));
                } else {
                    rows.push(addPartyInfo(i + '-pendingInvite',
                        <>{t('Uzavřená pozvánka')} ({getOption(ed.inviteStatus, inviteStatusOptions)?.label})</>,
                        t('Osoba má již uzavřenou pozvánku'), 'info'));
                }
            }
            if (rowStatusType !== 'pendingUpdateInvite' && ed.updateInviteStatus) {
                if (ed.updateInviteStatus === JsonPartyEventDetailsUpdateInviteStatusEnum.Pending
                    || ed.updateInviteStatus === JsonPartyEventDetailsUpdateInviteStatusEnum.Returned
                    || ed.updateInviteStatus === JsonPartyEventDetailsUpdateInviteStatusEnum.Accepted) {
                    rows.push(addPartyInfo(i + '-pendingUpdateInvite',
                        <>{t('Aktivní výzva')} ({getOption(ed.updateInviteStatus, updateInviteStatusOptions)?.label})</>,
                        t('Osoba má již rozpracovanou výzvu'), 'info'));
                } else {
                    rows.push(addPartyInfo(i + '-pendingUpdateInvite',
                        <>{t('Uzavřená výzva')} ({getOption(ed.updateInviteStatus, updateInviteStatusOptions)?.label})</>,
                        t('Osoba má již uzavřenou výzvu'), 'info'));
                }
            }
        });
    }

    if (!rows.length) {
        return null;
    }

    return <div className={'event-details'}>
        {rows}
    </div>
}

type EventSelectProps = {
    massAction: PartyMassActionValues<any>,
    setEventId: (eventId: number) => void,
    setTemplates: (templates?: JsonTemplate[]) => void
    eventId?: number,
}

const EventSelect = (props: EventSelectProps) => {
    const {eventId, massAction, setEventId, setTemplates} = props;
    const {parties} = massAction;
    const {action} = massAction.values;

    const dispatch = useAppDispatch();
    const {setFieldValue, setFormikState} = useFormikContext<FormikContextType<JsonEventPartyMassActionRequest>>();
    const codebooks = useAppSelector<CodebookState>(selectCodebooks);

    const eventDayNumbers: number[] = useMemo(() => {
        if (!codebooks || !eventId) {
            return [];
        }
        return createOptions({codebookName: 'eventDay', scope: eventId, asNumber: true, sortByKeys: true}, codebooks).map((o) => o.value as number)
    }, [codebooks, eventId]);

    const handleEventIdChange = useCallback(async (eventId?: number) => {
        const isInviteGuest = action === JsonEventPartyMassActionRequestActionEnum.InviteGuest;
        const isInviteOrg = action === JsonEventPartyMassActionRequestActionEnum.InviteOrg;
        if (isInviteGuest || isInviteOrg) {
            const templates = getApiResult<JsonTemplate[]>(await dispatch(fetchNotificationTemplates({
                eventId,
                notificationType: action as any as GetNotificationTemplatesUsingGETNotificationTypeEnum
            })));
            const primaryTemplate = templates?.find((t => t.isPrimary));

            setTemplates(templates);
            setFormikState((s) => {
                const {invite} = (s as FormikState<JsonEventPartyMassActionRequest>).values;
                const currentDays = invite?.inviteData?.eventDays;
                return {
                    ...s, values: {
                        ...s.values, invite: {
                            ...invite, inviteData: {
                                ...invite?.inviteData,
                                eventDays: isInviteGuest ? currentDays?.filter(d => eventDayNumbers.indexOf(d) >= 0) : invite?.inviteData?.eventDays,
                                bodyBottom: !!primaryTemplate?.bodyBottom ? primaryTemplate?.bodyBottom : invite?.inviteData?.bodyBottom,
                                bodyTop: !!primaryTemplate?.bodyTop ? primaryTemplate.bodyTop : invite?.inviteData?.bodyTop,
                                templateId: !!primaryTemplate?.templateId ? primaryTemplate.templateId : invite?.inviteData?.templateId
                            }
                        }
                    }
                }
            });
        }

        const {filtered} = filterValid(action, parties, eventId, 'valid');
        setFieldValue('items', filtered ? filtered.map((item) => ({
            partyId: item.partyId,
            eventPartyId: item.eventDetails?.find((ed) => ed.eventId === eventId)?.eventPartyId
        } as JsonEventPartyInfo)) : []);

    }, [eventDayNumbers, action, parties, setTemplates, setFieldValue, setFormikState, dispatch])

    useEffect(() => {
        handleEventIdChange(eventId).then();
    }, [eventId, handleEventIdChange])

    return <SelectFormField name="eventId" label={'Událost'}
        codebookProps={{codebookName: 'eventActive', asNumber: true, sortByKeys: true, reversed: true}}
        onChange={setEventId}
    />
}

type AttachmentsProps = {
    values: JsonEventPartyMassActionRequest
}

const Attachments = (props: AttachmentsProps) => {
    const {values} = props;

    const t = useAppTranslation();
    const {setFieldValue} = useFormikContext<FormikContextType<JsonEventPartyMassActionRequest>>();
    const [editFiles, setEditFiles] = useState<JsonFile[] | undefined>(undefined);
    const files = values.invite?.inviteData?.attachments;

    const handleEditFiles = useCallback((files?: JsonFile[]) => {
        setEditFiles(files || []);
    }, []);

    const handleCancelFiles = useCallback(() => {
        setEditFiles(undefined);
    }, []);

    const handleSaveFiles = useCallback((values: JsonFile[] | undefined) => {
        setFieldValue('invite.inviteData.attachments', values);
        setEditFiles(undefined);
    }, [setFieldValue]);

    return <Box style={{color: 'var(--color-warning)'}} className={'event-action-errors file-list'}>{(values.invite?.inviteData?.attachments?.length || 0) > 0
        ? <>
            {t('Standardní příloha bude nahrazena:')}
            {values.invite?.inviteData?.attachments?.map((a, i) => {
                return <span key={i}>{renderFile(a)}</span>
            })}
            <Button size={'small'} color={'warning'} onClick={() => {
                handleSaveFiles(undefined);
            }}><DeleteForeverRounded/> <span>{t('Zrušit')}</span></Button>
        </>
        : <>
            <Button size={'small'} color={'secondary'} sx={{float: 'right'}} onClick={() => {
                handleEditFiles(files);
            }}><AttachFileRounded/> <span>{t('Přiložit vlastní soubor')}</span></Button>
        </>}
        {!!editFiles && <FileModal item={{files: []}}
			title={t('Upravit přílohy pozvánky')}
			info={<Grid item xs={12}>
                <p dangerouslySetInnerHTML={{__html: t('Vybrané soubory <strong>nahradí</strong> standardní přílohu pozvánky.')}}></p>
                <p dangerouslySetInnerHTML={{__html: t('Soubory by měly být co nejmenší a ideálně ve formátu PDF (riziko pádu do spamu).')}}></p>
            </Grid>}
			onFileUploaded={handleSaveFiles}
			onCancel={handleCancelFiles}
		/>}
    </Box>
}

const defaultState: PartiesGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: undefined,
        action: undefined,
        eventId: undefined
    },
};

type RowStatusType = 'valid' | 'alreadyThere' | 'missingEmail' | 'pendingInvite' | 'pendingUpdateInvite';

const getRowStatus = (item: JsonPartyInfo, action?: JsonEventPartyMassActionRequestActionEnum, eventId?: number): RowStatusType => {
    const ed = item.eventDetails?.find((ed) => ed.eventId === eventId);
    if (action === JsonEventPartyMassActionRequestActionEnum.Register) {
        if (ed?.status && ed.status !== JsonPartyEventDetailsStatusEnum.Deleted) {
            return 'alreadyThere';
        } else {
            return 'valid';
        }
    }
    if (!item.email) {
        return 'missingEmail';
    }
    if (action === JsonEventPartyMassActionRequestActionEnum.InviteSupp) {
        if (ed?.updateInviteStatus) {
            if (ed?.updateInviteStatus === JsonPartyEventDetailsUpdateInviteStatusEnum.Pending
                || ed?.updateInviteStatus === JsonPartyEventDetailsUpdateInviteStatusEnum.Returned
                || ed?.updateInviteStatus === JsonPartyEventDetailsUpdateInviteStatusEnum.Accepted) {
                return 'pendingUpdateInvite';
            }
        }
    } else if (ed?.inviteStatus) {
        if (ed.inviteStatus === JsonPartyEventDetailsInviteStatusEnum.Pending
            || ed.inviteStatus === JsonPartyEventDetailsInviteStatusEnum.Returned
            || ed.inviteStatus === JsonPartyEventDetailsInviteStatusEnum.Accepted) {
            return 'pendingInvite';
        }
    }

    return 'valid';
}

const getRowClassNames = (item: JsonPartyInfo, filter?: LocalFilter): string[] | undefined => {
    switch (getRowStatus(item, filter?.action, filter?.eventId)) {
        case 'alreadyThere':
        case 'pendingInvite':
        case 'pendingUpdateInvite':
        case 'missingEmail':
            return ['data-grid-invalid-row'];
    }
    return undefined;
}

const filterValid = (action: JsonEventPartyMassActionRequestActionEnum | undefined, parties: JsonPartyInfo[], eventId: number | undefined, showMode: ShowMode) => {
    let alreadyThere = 0, missingEmail = 0, pendingInvite = 0;
    const filtered = parties.filter((item) => {
        let valid = false;
        switch (getRowStatus(item, action, eventId)) {
            case 'alreadyThere':
                alreadyThere++;
                break;
            case 'missingEmail':
                missingEmail++;
                break;
            case 'pendingInvite':
            case 'pendingUpdateInvite':
                pendingInvite++;
                break;
            case 'valid':
            default:
                valid = true;
        }

        return showMode === 'all' || (valid && showMode === 'valid') || (!valid && showMode === 'invalid');
    });
    return {filtered, alreadyThere, missingEmail, pendingInvite};
}

const texts: { [key in JsonEventPartyMassActionRequestActionEnum]?: [string, string, string] } = {
    [JsonEventPartyMassActionRequestActionEnum.Register]: [
        'Přidat do události',
        'Do události bude přidáno {{count}} osob',
        'Není nikdo, koho by bylo možné do události přidat'],
    [JsonEventPartyMassActionRequestActionEnum.InviteGuest]: [
        'Pokračovat na náhled',
        'Pozvánka bude odeslána {{count}} hostům',
        'Není nikdo, koho by bylo možné do události pozvat'],
    [JsonEventPartyMassActionRequestActionEnum.InviteOrg]: [
        'Pokračovat na náhled',
        'Pozvánka bude odeslána {{count}} organizátorům',
        'Není nikdo, koho by bylo možné do události pozvat'],
    [JsonEventPartyMassActionRequestActionEnum.InviteSupp]: [
        'Pokračovat na náhled',
        'Výzva bude odeslána {{count}} dodavatelům',
        'Není nikdo, koho by bylo možné vyzvat'],
};

const PartyRegisterToEventForm = (props: Props) => {

    const {massAction, onSave} = props;
    const {parties} = massAction;
    const {action} = massAction.values;
    const isInviteGuest = action === JsonEventPartyMassActionRequestActionEnum.InviteGuest;
    const isInviteOrg = action === JsonEventPartyMassActionRequestActionEnum.InviteOrg;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();
    const [showMode, setShowMode] = useState<ShowMode>('valid');
    const [eventId, setEventId] = useState<number | undefined>(massAction.values.eventId);
    const [templates, setTemplates] = useState<JsonTemplate[] | undefined>(undefined);
    const [isExtraLimitCustom, setIsExtraLimitCustom] = useState(false);

    const validate = useCallback((values: JsonEventPartyMassActionRequest) => {
        let errors = {} as FormikErrors<JsonEventPartyMassActionRequest>;
        if (!values.eventId) {
            errors.eventId = t('Vyberte událost');
        }
        if (!values.groupId) {
            errors.groupId = t('Vyberte skupinu');
        }
        if (values.action === JsonEventPartyMassActionRequestActionEnum.InviteGuest) {
            const inviteData = values.invite ? values.invite.inviteData : undefined;
            if (!inviteData?.eventDays?.length) {
                setNestedKey(errors, 'invite.inviteData.eventDays', t('Vyberte alespoň jeden den'));
            }
            if (!inviteData?.extraLimit && inviteData?.extraLimit !== 0) {
                setNestedKey(errors, 'invite.inviteData.extraLimit', t('Zvolte doprovod'));
            }
            if (!(inviteData?.isPark === true || inviteData?.isPark === false)) {
                setNestedKey(errors, 'invite.inviteData.isPark', t('Vyberte parkování'));
            }
            if (!inviteData?.bodyTop || inviteData?.bodyTop.length < 10) {
                if (!values.skipEmail) {
                    setNestedKey(errors, 'invite.inviteData.bodyTop', t('Zadejte prosím průvodní text'));
                }
            }
        }
        return errors;
    }, [t]);

    const actions = useCallback(({values, isSubmitting}: FormikProps<JsonEventPartyMassActionRequest>, props: FormProps<JsonEventPartyMassActionRequest>) => {
        const {eventId, items} = values;
        const itemsCount = items?.length || 0;
        if (!action) {
            return null;
        }
        const textValues = texts[action];
        if (!textValues) {
            return null; // should never happen
        }

        return <Grid item xs={12} sx={{margin: '-5px 0 -10px 0'}}>
            <Grid container columnSpacing={2} justifyContent="flex-end">
                <Grid item sx={{flexGrow: 1}}>
                    {eventId
                        ? (itemsCount > 0
                            ? <Alert severity={'info'} className={'event-action-errors'}>{t(textValues[1], {count: itemsCount})}</Alert>
                            : <Alert severity={'warning'} className={'event-action-errors'}>{t(textValues[2])}</Alert>)
                        : null /* <Alert severity={'warning'} className={'event-action-errors'}>{t('Zvolte nejprve událost')}</Alert>*/}
                </Grid>
                {values.action !== JsonEventPartyMassActionRequestActionEnum.Register && <Grid item>
					<CheckboxField name={'skipEmail'} color={'default'} label={t('Neposílat email')}/>
				</Grid>}
                {props.onCancel && <Grid item>
					<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || t('Storno')}</Button>
				</Grid>}
                <Grid item>
                    <Button variant="contained" type="submit" color={'success'} disabled={!eventId || isSubmitting || !itemsCount}>
                        {t(textValues[0])}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    }, [action, t]);

    const {filtered, alreadyThere, missingEmail, pendingInvite, isParkingDisabled} = useMemo(() => {
        const res = filterValid(action, parties, eventId, showMode);

        const nonGuests = res.filtered.filter((p) => !!p.eventDetails?.find((ed) => ed.eventId === eventId && ed.groupId && ed.groupId !== FIXED_VIP_GROUP_ID))
        let isParkingDisabled = false;
        if (res.filtered.length > 0 && nonGuests.length === res.filtered.length) {
            isParkingDisabled = true;
        }

        return {...res, isParkingDisabled};
    }, [action, parties, eventId, showMode])

    const cols = useMemo(() => {
        const cols: DataGridCol<JsonPartyInfo, LocalFilter>[] = [
            // createCol('ID', 'partyId', 40, 'EMS ID', (v) => <small>{v}</small>),
            // createCol('SIWI', 'siwiId', 120, 'Siwidata ID', (v) => <pre>{v}</pre>),
            createCol('Jméno', 'fullName', 150, undefined, (v, item) => <strong>{partyName(item)}</strong>),
            createCol('P', 'sex', 20, 'Pohlaví', (v) => v ? <small><CodebookValue value={v} name={'sex'} formatValue={(v) => v[0]}/></small> : null),
            createCol('R', 'birthDate', 35, 'Rok narození', (v) => v ? <small>{dateToGuiAs(v, 'Y')}</small> : null),
            createCol('Email', 'email', 180, undefined, (v) => <small>{v}</small>),
            // createCol('Org', 'orgCompanyName', 100, 'Organizace'),
            createCol('Štítky / Firma', 'tags', 170, undefined, (v, item) => {
                const items = [];
                if (v) {
                    items.push(<PartyTags key={'tags'} tags={v} partyId={item.partyId} quickEditTagId={FIXED_INVITE_TAG_ID}/>);
                }
                if (item.companyId) {
                    items.push(<small style={v ? {display: 'inline-block', marginTop: '10px'} : undefined} key={'company'}><CodebookValue value={item.companyId} name={'company'}/></small>)
                }
                return items;
            }),
            {
                title: 'Poznámka',
                size: 190,
                col: 'note',
                renderValue: (v, item, filter) => <small>{v}</small>
            },
            {
                title: 'Stav',
                size: 140,
                col: 'eventDetails',
                renderValue: (v, item, filter) => <PartyDetails item={item} filter={filter as LocalFilter}/>
            }];
        return cols;
    }, []);

    const grid = useMemo(() => {
        const itemsState: ItemsState<JsonPartyInfo, LocalFilter> = {
            loading: false,
            count: 0,
            items: filtered,
            filter: {action, eventId}
        };

        return <>
            {(missingEmail > 0 || alreadyThere > 0 || pendingInvite > 0) && <Grid container style={{padding: '5px 0 10px 0', alignItems: 'center'}}><Grid item xs={8}>
				<Alert severity={'warning'} className={'event-action-errors'}>
                    {action !== JsonEventPartyMassActionRequestActionEnum.Register
                        ? t('Některé osoby nelze pozvat ({{skipped}}):', {skipped: missingEmail + alreadyThere + pendingInvite})
                        : t('Některé osoby nelze registrovat ({{skipped}}):', {skipped: missingEmail + alreadyThere + pendingInvite})}
                    {missingEmail > 0 && <span>{t('nemají email ({{missingEmail}})', {missingEmail})}</span>}
                    {alreadyThere > 0 && <span>{t('již v události ({{alreadyThere}})', {alreadyThere})}</span>}
                    {pendingInvite > 0 && <span>{t('nedokončená pozvánka ({{pendingInvite}})', {pendingInvite})}</span>}
				</Alert>
			</Grid><Grid item xs={4} sx={{textAlign: 'right'}}>
				<ButtonGroupPlain name={'showMode'} options={showModeOptions} currentValue={showMode} onChange={(v) => {
                    setShowMode(v || showMode);
                }}/>
			</Grid></Grid>}
            <DataGrid
                cols={cols}
                defaultState={defaultState}
                itemsState={itemsState}
                mode={DataGridMode.CLIENT}
                tableProps={{
                    minHeight: '100px',
                    maxHeight: "clamp(100px, calc(100vh - "
                        + ((isInviteGuest || isInviteOrg ? 600 : 300)
                            + (missingEmail > 0 || alreadyThere > 0 || pendingInvite > 0 ? 50 : 0))
                        + "px), 200px)"
                }}
                emptyListMessage={t(JsonEventPartyMassActionRequestActionEnum.Register ? 'Není možné registrovat žádné osoby' : 'Není možné pozvat žádné osoby')}
                getRowClassNames={getRowClassNames}
            />
        </>;
    }, [filtered, alreadyThere, missingEmail, pendingInvite, action, isInviteGuest, isInviteOrg, cols, eventId, showMode, t]);

    const children = useCallback(({values, errors, setFieldValue}: FormikProps<JsonEventPartyMassActionRequest>) => {
        const {eventId} = values;

        const extraLimitError = (errors as any)?.invite?.inviteData?.extraLimit;

        return <>
            <Grid item xs={5}>
                <EventSelect eventId={eventId} massAction={massAction} setEventId={setEventId} setTemplates={setTemplates}/>
            </Grid>
            <Grid item xs={4}>
                <SelectFormField name="groupId" label={'Skupina'}
                    codebookProps={{codebookName: isInviteGuest ? 'groupGuest' : 'group', asNumber: true}}/>
            </Grid>
            {isInviteGuest && eventId && <>
				<Grid item xs={3}>
					<TextFormField name="invite.replyUntil" label={t('Uzávěrka')} type={'date'} maxlength={10} clearable
						minValue={dateToGuiAs(new Date(), 'yyyy-MM-dd')}/>
				</Grid>
				<Grid item xs={5}>
					<EventDaysField name="invite.inviteData.eventDays" eventId={eventId}/>
				</Grid>
				<Grid item xs={4}>
					<Grid container direction={'row-reverse'}>
						<Grid item xs={12}>
							<ButtonGroupPlain name={'extraLimitToggle'}
								options={inviteExtraLimitOptions}
								fullWidth
								currentValue={isExtraLimitCustom ? FAKE_VALUE_CUSTOM : values.invite?.inviteData?.extraLimit} onChange={(v) => {
                                if (v === FAKE_VALUE_CUSTOM) {
                                    setIsExtraLimitCustom(true);
                                    setFieldValue('invite.inviteData.extraLimit', 10, true);
                                } else {
                                    setIsExtraLimitCustom(false);
                                    setFieldValue('invite.inviteData.extraLimit', v, true);
                                }
                            }} error={extraLimitError} showError={!!extraLimitError}/>
						</Grid>
                        {isExtraLimitCustom && <Grid item lg={5} xs={12}><TextFormFieldPlain name="extraLimitCustom" label={t('Vlastní limit')} type={'number'}
							minValue={1} maxValue={99} currentValue={values.invite?.inviteData?.extraLimit} onChange={(v) => {
                            setFieldValue('invite.inviteData.extraLimit', v === undefined || v === '' ? undefined : parseInt(v, 10), true);
                        }}/></Grid>}
					</Grid>
				</Grid>
				<Grid item xs={3}>
                    {isParkingDisabled
                        ? <em style={{color: 'var(--gray-border)'}}>{t('Organizátorům parkování nenabízíme')}</em>
                        : <ButtonGroupField name="invite.inviteData.isPark" options={inviteIsParkOptions} fullWidth/>}
				</Grid>
			</>}
            {isInviteOrg && eventId && <>
				<Grid item xs={3}>
					<TextFormField name="invite.replyUntil" label={t('Uzávěrka')} type={'date'} maxlength={10} clearable
						minValue={dateToGuiAs(new Date(), 'yyyy-MM-dd')}/>
				</Grid>
			</>}
            {(isInviteGuest || isInviteOrg) && !!eventId && <>
				<Grid item xs={12} sx={{marginTop: '-5px', position: 'relative'}}>
					<TextFormField name="invite.inviteData.bodyTop" label={t('Horní text')} type={'textarea'} minRows={6} maxlength={4000} maxRows={12}/>
                    {!!templates && !values.invite?.inviteData?.bodyTop
                        && <div style={{position: 'absolute', bottom: '0', right: '10px', color: 'gray'}}>{t('Předvyplnit horní i dolní text')}:
                            {templates?.map((t, i) => {
                                return <Button key={i} variant={'text'} size={'small'} sx={{fontSize: '90%'}} onClick={() => {
                                    setFieldValue('invite.inviteData.bodyTop', t.bodyTop);
                                    setFieldValue('invite.inviteData.bodyBottom', t.bodyBottom);
                                    setFieldValue('invite.inviteData.templateId', t.templateId);
                                }}>{t.templateData?.title || t.templateId}</Button>;
                            })}</div>}
				</Grid>
				<Grid item xs={12} sx={{marginTop: '-5px'}}>
					<TextFormField name="invite.inviteData.bodyBottom" label={t('Dolní text')} type={'textarea'} minRows={3} maxlength={4000} maxRows={5}/>
				</Grid>
			</>}
            {isInviteGuest && <Grid item xs={12}>
				<Attachments values={values}/>
			</Grid>}
            <Grid item xs={12}>
                {grid}
            </Grid>
        </>;
    }, [isExtraLimitCustom, isParkingDisabled, templates, isInviteOrg, isInviteGuest, grid, massAction, setEventId, t]);

    const handleSave = useCallback(async (values: JsonEventPartyMassActionRequest) => {

        if (action === JsonEventPartyMassActionRequestActionEnum.InviteOrg
            || action === JsonEventPartyMassActionRequestActionEnum.InviteGuest
            || action === JsonEventPartyMassActionRequestActionEnum.InviteSupp) {
            if (!!values.skipEmail) {
                const result = await modal.confirm({
                    title: t('Potvrzení vytvoření pozvánky'),
                    message: <div>
                        <p>{t('Pro zvolené osoby ({{count}}) bude vytvořena pozvánka, bez odeslání emailu.', {count: values.items?.length})}</p>
                    </div>,
                    cancelText: 'Zpět',
                    confirmColor: 'success',
                    confirmText: t('Vytvořit pozvánky ({{count}})', {count: values.items?.length}),
                    confirmIcon: <SendRounded/>,
                } as ModalProps);
                if (result !== 'CONFIRM') {
                    return;
                }

            } else {
                let notificationType: PreviewNotificationUsingPOSTNotificationTypeEnum;
                if (action === JsonEventPartyMassActionRequestActionEnum.InviteOrg) {
                    notificationType = PreviewNotificationUsingPOSTNotificationTypeEnum.InviteOrg;
                } else if (action === JsonEventPartyMassActionRequestActionEnum.InviteSupp) {
                    notificationType = PreviewNotificationUsingPOSTNotificationTypeEnum.InviteSupp;
                } else {
                    notificationType = PreviewNotificationUsingPOSTNotificationTypeEnum.InviteGuest;
                }

                const email = getApiResult<JsonEmailMessage>(await dispatch(previewNotification({
                    request: {
                        invite: {...values.invite, eventId},
                        recipients: values.items?.map((ep) => filtered.find((f) => f.partyId === ep.partyId)?.email || ('' + ep.partyId))
                    },
                    notificationType
                })));

                const result = await modal.confirm({
                    title: t('Potvrzení odeslání pozvánky'),
                    message: <div>
                        <p>{t('Na zvolené osoby ({{count}}) bude odeslána následující zpráva', {count: values.items?.length})}:</p>
                        <EmailPreview email={email} showRecipient/>
                    </div>,
                    cancelText: 'Zpět',
                    confirmColor: 'success',
                    confirmText: t('Odeslat všem příjemcům ({{count}})', {count: values.items?.length}),
                    confirmIcon: <SendRounded/>,
                } as ModalProps);
                if (result !== 'CONFIRM') {
                    return;
                }
            }
        }

        if (onSave && massAction) {
            onSave({...massAction, values: values})
        }
    }, [filtered, eventId, action, massAction, onSave, modal, dispatch, t])

    return <FormContainer
        item={props.massAction.values}
        validate={validate}
        actions={actions}
        children={children}
        onCancel={props.onCancel}
        onSave={handleSave}
    />;
}

export default PartyRegisterToEventForm;
