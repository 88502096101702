import FormModal from "../components/form/FormModal";
import EventAmmoLogForm, {AmmoLogFormProps} from "../components/event/EventAmmoLogForm";

const AmmoLogModal = (props: AmmoLogFormProps) => {

    return (
        <FormModal title={props.title} {...props} maxWidth={'sm'} dialogClassName={'rsvp-review'}>
            <EventAmmoLogForm {...props} />
        </FormModal>
    );
}

export default AmmoLogModal;
