import {Container, Grid, LinearProgress, Tab, Tabs} from '@mui/material';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useAppTranslation} from '../services/i18n';
import {fetchEvent, fetchEventInfo, saveEvent} from '../store/events';
import {useParams} from "react-router-dom";
import {JsonAuthUserInfoRightsEnum, JsonEvent, JsonEventInfo, JsonEventStatusEnum} from '../generated-api';
import {useAppDispatch, useAppSelector} from "../store";
import PageHeader from "../components/layout/PageHeader";
import EventDaysList from "../components/event/EventDaysList";
import EventPlacesList from "../components/event/EventPlacesList";
import InfoBox from "../components/layout/InfoBox";
import EventArticlesList from "../components/event/EventArticlesList";
import EventContingentsList from "../components/event/EventContingentsList";
import {dateDiff, dateToGui} from "../helpers/date";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../helpers/api";
import EventModal from "./EventModal";
import {useNavigate} from "react-router";
import {fetchCodebooks} from "../store/codebooks";
import EventGroupsList from "../components/event/EventGroupsList";
import EventCateringMatrix from "../components/event/EventCateringMatrix";
import {selectAuthInfo} from "../store/selectors";
import {AuthState} from "../store/auth";
import EventAmmoLogsList from "../components/event/EventAmmoLogsList";

const EventPage = () => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const {user, rights} = useAppSelector<AuthState>(selectAuthInfo);
    const isAdmin = rights.includes(JsonAuthUserInfoRightsEnum.Admin);

    const [isLoading, setIsLoading] = useState(false);
    const [event, setEvent] = useState<JsonEventInfo>();
    const [editEvent, setEditEvent] = useState<JsonEvent>();

    const tab = params.tab || (rights.includes(JsonAuthUserInfoRightsEnum.Ammo) ? 'ammo' : 'event');
    const eventId = params.eventId ? parseInt(params.eventId, 10) : undefined;

    const handleFetchEvent = useCallback(() => {
        setIsLoading(true);
        if (eventId) {
            dispatch(fetchEventInfo(eventId)).then((res) => {
                const event = getApiResult<JsonEventInfo>(res);
                if (event?.eventId) {
                    setEvent(getApiResult(res))
                } else {
                    navigate('/events');
                }
            });
        } else {
            setEvent({});
            setEditEvent({});
        }
        setIsLoading(false);

    }, [eventId, dispatch, navigate]);

    const handleEditEvent = useCallback(() => {
        if (eventId) {
            dispatch(fetchEvent(eventId)).then((res) => setEditEvent(getApiResult(res)));
        } else {
            setEditEvent({eventId});
        }
    }, [eventId, dispatch]);

    const handleSaveEvent = useCallback(async (event: JsonEvent, isDelete?: boolean) => {
        const res = await dispatch(saveEvent({...event, status: isDelete ? JsonEventStatusEnum.Deleted : event.status}));
        const item = getApiResult<JsonEvent>(res);
        if (item) {
            addApiResultMessage(res, {
                [ApiChangeType.NO_CHANGE]: 'Událost {{title}} ponechána beze změn',
                [ApiChangeType.UPDATED]: isDelete ? 'Událost {{title}} úspěšně odstraněna' : 'Událost {{title}} úspěšně upravena',
                [ApiChangeType.CREATED]: 'Událost {{title}} úspěšně vytvořena'
            }, t, dispatch);
            setEditEvent(undefined);
            dispatch(fetchCodebooks());
            if (!eventId) {
                navigate('/events/' + item.eventId);
                return;
            }
            handleFetchEvent();
        }

    }, [eventId, handleFetchEvent, dispatch, navigate, t])

    const handleCancelEvent = useCallback(() => {
        setEditEvent(undefined);
        if (!eventId) {
            navigate('/events');
        }
    }, [eventId, navigate]);

    const handleTabChange = useCallback((_: unknown, tab: string) => {
        setIsLoading(true);
        navigate('/events/' + event?.eventId + (tab === 'event' ? '' : ('/' + tab)));
    }, [navigate, event?.eventId]);

    useEffect(() => {
        handleFetchEvent();
    }, [handleFetchEvent]);

    if (!event) {
        return <LinearProgress/>
    }

    return <Container>
        <Grid container spacing={2} className={'page-content page-tabs'}>
            {isAdmin && <Grid item sm={6}>
                <PageHeader title={event.title || ''}/>
            </Grid>}
            {!!event.eventId && isAdmin && <Grid item sm={6}>
				<Tabs
					value={tab}
					onChange={handleTabChange}
					variant="scrollable"
					scrollButtons="auto"
				>
					<Tab key={0} value={'event'} label={t('Událost')}/>
					<Tab key={1} value={'groups'} label={t('Skupiny')}/>
                    <Tab key={2} value={'catering'} label={t('Catering')}/>
                    <Tab key={3} value={'ammo'} label={t('Střelivo')}/>
				</Tabs>
			</Grid>}
            <Grid item sm={12}>
                {!!event.eventId && tab === 'event' && <Grid container spacing={2}>
					<Grid item md={6}>
						<InfoBox title={'Událost'} actions={[{title: 'Upravit událost', color: 'primary', action: handleEditEvent}]}>
                            {isLoading && <LinearProgress/>}
							<table className={'info-box-table'}>
								<tbody>
								<tr>
									<th style={{width: '20%'}}>{t('Název')}</th>
									<td style={{width: '40%'}} colSpan={2}>{event.title}</td>
									<th style={{width: '20%'}}>{t('Zkratka')}</th>
									<td style={{width: '20%'}}>{event.abbr}</td>

								</tr>
								<tr>
									<th>{t('Popis')}</th>
									<td colSpan={4}>{event.description}</td>
								</tr>
								<tr>
									<th>{t('Příprava od-do')}</th>
									<td style={{width: '20%'}}>{dateToGui(event.prepareFrom)}</td>
									<td>{dateToGui(event.prepareTo)}</td>
									<td colSpan={2}>({t('{{count}} dní', {count: dateDiff(event.prepareFrom, event.prepareTo)})})</td>
								</tr>
								<tr>
									<th>{t('Akce od-do')}</th>
									<td>{dateToGui(event.eventFrom)}</td>
									<td>{dateToGui(event.eventTo)}</td>
									<td colSpan={2}>({t('{{count}} dní', {count: dateDiff(event.eventFrom, event.eventTo)})})</td>
								</tr>
								<tr>
									<th>{t('Úklid od-do')}</th>
									<td>{dateToGui(event.cleanFrom)}</td>
									<td>{dateToGui(event.cleanTo)}</td>
									<td colSpan={2}>({t('{{count}} dní', {count: dateDiff(event.cleanFrom, event.cleanTo)})})</td>
								</tr>
								<tr>
									<th>{t('Siwidata ID')}</th>
									<td colSpan={4}>
										<pre>{event.siwiId}</pre>
									</td>
								</tr>
								</tbody>
							</table>
						</InfoBox>
						<InfoBox title={'Dny akce'}>
							<EventDaysList eventId={event.eventId}/>
						</InfoBox>
						<InfoBox title={'Kontingenty'}>
							<EventContingentsList eventId={event.eventId}/>
						</InfoBox>
					</Grid>
					<Grid item md={6}>
						<InfoBox title={'Místa'}>
							<EventPlacesList eventId={event.eventId} maxHeight={'240px'} minHeight={'240px'}/>
						</InfoBox>
						<InfoBox title={'Artikly'}>
							<EventArticlesList eventId={event.eventId} maxHeight={'240px'}/>
						</InfoBox>
					</Grid>

					<Grid item md={6}>
					</Grid>
				</Grid>}
                {!!event.eventId && tab === 'groups' && <Grid container spacing={2}>
					<Grid item sm={12}>
						<EventGroupsList event={event} onSaveEvent={handleSaveEvent}/>
					</Grid>
				</Grid>}
                {!!event.eventId && tab === 'catering' && !isLoading && <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <EventCateringMatrix event={event} onSaveEvent={handleSaveEvent}/>
                    </Grid>
                </Grid>}
                {!!event.eventId && tab === 'ammo' && !isLoading && <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <EventAmmoLogsList event={event} user={user} />
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>
        {editEvent && <EventModal
            mode={'event'}
			item={editEvent}
			onCancel={handleCancelEvent}
			onSave={handleSaveEvent}
			onDelete={editEvent.eventId ? (event) => handleSaveEvent(event, true) : undefined}
			deleteButtonTitle={editEvent.status === JsonEventStatusEnum.Deleted ? 'Obnovit' : 'Archivovat'}
			deleteConfirmText={editEvent.status === JsonEventStatusEnum.Deleted ? 'Skutečně obnovit událost {{title}}?' : 'Skutečně archivovat událost {{title}}?'}
		/>}
    </Container>
}

export default EventPage;
