/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonConfiguration,
    JsonConfigurationFromJSON,
    JsonConfigurationFromJSONTyped,
    JsonConfigurationToJSON,
} from './JsonConfiguration';
import {
    JsonUserInfo,
    JsonUserInfoFromJSON,
    JsonUserInfoFromJSONTyped,
    JsonUserInfoToJSON,
} from './JsonUserInfo';

/**
 * 
 * @export
 * @interface JsonAuthUserInfo
 */
export interface JsonAuthUserInfo {
    /**
     * 
     * @type {JsonConfiguration}
     * @memberof JsonAuthUserInfo
     */
    config?: JsonConfiguration;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonAuthUserInfo
     */
    rights?: Array<JsonAuthUserInfoRightsEnum>;
    /**
     * 
     * @type {JsonUserInfo}
     * @memberof JsonAuthUserInfo
     */
    user?: JsonUserInfo;
}

/**
* @export
* @enum {string}
*/
export enum JsonAuthUserInfoRightsEnum {
    Admin = 'ADMIN',
    Supervisor = 'SUPERVISOR',
    Ammo = 'AMMO'
}

export function JsonAuthUserInfoFromJSON(json: any): JsonAuthUserInfo {
    return JsonAuthUserInfoFromJSONTyped(json, false);
}

export function JsonAuthUserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonAuthUserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': !exists(json, 'config') ? undefined : JsonConfigurationFromJSON(json['config']),
        'rights': !exists(json, 'rights') ? undefined : json['rights'],
        'user': !exists(json, 'user') ? undefined : JsonUserInfoFromJSON(json['user']),
    };
}

export function JsonAuthUserInfoToJSON(value?: JsonAuthUserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': JsonConfigurationToJSON(value.config),
        'rights': value.rights,
        'user': JsonUserInfoToJSON(value.user),
    };
}

