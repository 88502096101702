/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonArticle,
    JsonArticleFromJSON,
    JsonArticleFromJSONTyped,
    JsonArticleToJSON,
} from './JsonArticle';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonEventPartySeating,
    JsonEventPartySeatingFromJSON,
    JsonEventPartySeatingFromJSONTyped,
    JsonEventPartySeatingToJSON,
} from './JsonEventPartySeating';

/**
 * 
 * @export
 * @interface JsonEventParty
 */
export interface JsonEventParty {
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    accredAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    accredStatus?: JsonEventPartyAccredStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventParty
     */
    allowedActions?: Array<JsonEventPartyAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    areas?: string;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonEventParty
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    contingentOwner?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    contractStatus?: JsonEventPartyContractStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventParty
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    eventDays?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventParty
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    eventPartyGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventParty
     */
    eventPartyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    formatCode?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventParty
     */
    groupId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    groupPosition?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventParty
     */
    partyId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonEventParty
     */
    prefAcm?: boolean;
    /**
     * 
     * @type {Array<JsonArticle>}
     * @memberof JsonEventParty
     */
    prefArticles?: Array<JsonArticle>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    realEventDays?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    reason?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventParty
     */
    rowNo?: number;
    /**
     * 
     * @type {JsonEventPartySeating}
     * @memberof JsonEventParty
     */
    seating?: JsonEventPartySeating;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    siwiAccredStatus?: JsonEventPartySiwiAccredStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    siwiAreas?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    siwiBarcode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    siwiContingentOwner?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    siwiEventDays?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    siwiFormatCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    siwiReason?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    siwiUpdatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    siwiUpdatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    status?: JsonEventPartyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventParty
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventParty
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventPartyAccredStatusEnum {
    SiwiManaged = 'SIWI_MANAGED',
    EmsManaged = 'EMS_MANAGED',
    EmsApproved = 'EMS_APPROVED'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyContractStatusEnum {
    None = 'NONE',
    Signed = 'SIGNED'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartySiwiAccredStatusEnum {
    None = 'NONE',
    EmsDraft = 'EMS_DRAFT',
    EmsPending = 'EMS_PENDING',
    SiwiAny = 'SIWI_ANY',
    Undefined = 'UNDEFINED',
    Approved = 'APPROVED',
    Printed = 'PRINTED',
    NoContingent = 'NO_CONTINGENT',
    Entered = 'ENTERED',
    Printqueue = 'PRINTQUEUE',
    Batchprint = 'BATCHPRINT',
    Deleted = 'DELETED'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    Pending = 'PENDING'
}

export function JsonEventPartyFromJSON(json: any): JsonEventParty {
    return JsonEventPartyFromJSONTyped(json, false);
}

export function JsonEventPartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventParty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accredAt': !exists(json, 'accredAt') ? undefined : json['accredAt'],
        'accredStatus': !exists(json, 'accredStatus') ? undefined : json['accredStatus'],
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'areas': !exists(json, 'areas') ? undefined : json['areas'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'contingentOwner': !exists(json, 'contingentOwner') ? undefined : json['contingentOwner'],
        'contractStatus': !exists(json, 'contractStatus') ? undefined : json['contractStatus'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventDays': !exists(json, 'eventDays') ? undefined : json['eventDays'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventPartyGuid': !exists(json, 'eventPartyGuid') ? undefined : json['eventPartyGuid'],
        'eventPartyId': !exists(json, 'eventPartyId') ? undefined : json['eventPartyId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'formatCode': !exists(json, 'formatCode') ? undefined : json['formatCode'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'groupPosition': !exists(json, 'groupPosition') ? undefined : json['groupPosition'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'prefAcm': !exists(json, 'prefAcm') ? undefined : json['prefAcm'],
        'prefArticles': !exists(json, 'prefArticles') ? undefined : ((json['prefArticles'] as Array<any>).map(JsonArticleFromJSON)),
        'realEventDays': !exists(json, 'realEventDays') ? undefined : json['realEventDays'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'rowNo': !exists(json, 'rowNo') ? undefined : json['rowNo'],
        'seating': !exists(json, 'seating') ? undefined : JsonEventPartySeatingFromJSON(json['seating']),
        'siwiAccredStatus': !exists(json, 'siwiAccredStatus') ? undefined : json['siwiAccredStatus'],
        'siwiAreas': !exists(json, 'siwiAreas') ? undefined : json['siwiAreas'],
        'siwiBarcode': !exists(json, 'siwiBarcode') ? undefined : json['siwiBarcode'],
        'siwiContingentOwner': !exists(json, 'siwiContingentOwner') ? undefined : json['siwiContingentOwner'],
        'siwiEventDays': !exists(json, 'siwiEventDays') ? undefined : json['siwiEventDays'],
        'siwiFormatCode': !exists(json, 'siwiFormatCode') ? undefined : json['siwiFormatCode'],
        'siwiReason': !exists(json, 'siwiReason') ? undefined : json['siwiReason'],
        'siwiUpdatedAt': !exists(json, 'siwiUpdatedAt') ? undefined : json['siwiUpdatedAt'],
        'siwiUpdatedBy': !exists(json, 'siwiUpdatedBy') ? undefined : json['siwiUpdatedBy'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventPartyToJSON(value?: JsonEventParty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accredAt': value.accredAt,
        'accredStatus': value.accredStatus,
        'allowedActions': value.allowedActions,
        'areas': value.areas,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'contingentOwner': value.contingentOwner,
        'contractStatus': value.contractStatus,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventDays': value.eventDays,
        'eventId': value.eventId,
        'eventPartyGuid': value.eventPartyGuid,
        'eventPartyId': value.eventPartyId,
        'firstName': value.firstName,
        'formatCode': value.formatCode,
        'groupId': value.groupId,
        'groupPosition': value.groupPosition,
        'lastName': value.lastName,
        'note': value.note,
        'partyId': value.partyId,
        'prefAcm': value.prefAcm,
        'prefArticles': value.prefArticles === undefined ? undefined : ((value.prefArticles as Array<any>).map(JsonArticleToJSON)),
        'realEventDays': value.realEventDays,
        'reason': value.reason,
        'rowNo': value.rowNo,
        'seating': JsonEventPartySeatingToJSON(value.seating),
        'siwiAccredStatus': value.siwiAccredStatus,
        'siwiAreas': value.siwiAreas,
        'siwiBarcode': value.siwiBarcode,
        'siwiContingentOwner': value.siwiContingentOwner,
        'siwiEventDays': value.siwiEventDays,
        'siwiFormatCode': value.siwiFormatCode,
        'siwiReason': value.siwiReason,
        'siwiUpdatedAt': value.siwiUpdatedAt,
        'siwiUpdatedBy': value.siwiUpdatedBy,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

